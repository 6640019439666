/* eslint-disable eqeqeq, no-useless-concat */

import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import Moment from 'moment';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {matchSorter} from 'match-sorter';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { saveAs } from "file-saver";
import JSZip from "jszip";

class StudyTourList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            modal: false,
            detail: '',
            dataIntership: [],
            status: 1,
            btnDisabled: false,
            modalPreview: false,
        }
    }

    componentDidMount() {
        this.getDataIntership();
    }

    getDataIntership() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/intership/list';
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataIntership:data.data
            });
        });
    }

    handleInput(e) {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    editRow(row) {
        this.setState({
            modal: true,
            detail: row,
            status: 1
        })
    }

    async downloadRow(row) {
        const zipUrl = row.document

        try {
            const response = await fetch(zipUrl);
            if (!response.ok) throw new Error("Gagal mengunduh file ZIP");
      
            const blob = await response.blob();
            const zip = await JSZip.loadAsync(blob);
      
            zip.forEach(async (relativePath, file) => {
              const content = await file.async("blob");
              saveAs(content, file.name);
            });
      
            alert("File berhasil diekstrak dan diunduh!");
        } catch (error) {
            console.error("Error:", error);
            alert("Terjadi kesalahan saat mengunduh atau mengekstrak ZIP");
        }
    }

    closeDialog() {
        this.setState({
            modal: false
        })
    }

    closePreview() {
        this.setState({
            modalPreview: false
        })
    }

    saveRow() {
        this.setState({
            btnDisabled: true
        })

        var formData = new FormData();
        formData.append('status', this.state.status);
        formData.append('_method', 'PATCH');

        const url = API_URL.superStudent+'/intership/list/'+this.state.detail.kode_register;
        axios.post(url, formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data'
            } 
        })
        .then(response => response.data)
        .then((data) => {
            this.setState({ modal:false, btnDisabled: false });
            this.getDataIntership();
            toast.success("Update Sucsessfully");
        });

    }

    downloadFile = (e, url, filename) => {
        saveAs(url,filename);
    }

    renderDialog() {
        const { modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>Update Status</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Status</label>
                                    <select className="form-control" name="status" type="text" id="status" onChange={(e) => this.handleInput(e)}>
                                        <option value={1}>Follow Up</option>
                                        <option value={2}>Progress</option>
                                        <option value={3}>Document</option>
                                        <option value={4}>Cancel</option>
                                    </select>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderPreview(){
        const modalPreview = this.state.modalPreview;
        const { title } = this.state;
        return(
            <Modal className="modal-dialog" isOpen={modalPreview} toggle={() => this.closePreview()} size="lg">
              <ModalHeader toggle={() => this.closePreview()}>{title}</ModalHeader>
              <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                {/* {this.state.student_doc.map((doc, i) => {
                    const url_doc = [{ uri:doc.file_name }]
                    const url_download = doc.file_name;
                    return  
                })} */}
                <div className='mt-3'>
                    <h6><strong><li>{this.state.detail.document.split('/').pop()}</li></strong></h6>
                    {
                        this.state.detail.document.split('.').pop().toLowerCase == 'png' || this.state.detail.document.split('.').pop().toLowerCase == 'jpg' || 
                        this.state.detail.document.split('.').pop().toLowerCase == 'jpeg' ?
                        <img className='image' src={this.state.urlPreview} alt="" /> 
                        :
                        <DocViewer 
                            pluginRenderers={DocViewerRenderers} 
                            documents={[{ uri:this.state.detail.document }]}
                            config={{
                                header: {
                                    disableHeader: true,
                                    disableFileName: false,
                                    retainURLParams: false
                                }
                            }}
                        />
                    }
                    <div className="form-row">
                        <div className="col-md-6 mt-2" style={{textAlign: "end"}}>
                            <button onClick={(e) => this.downloadFile(e, this.state.detail.document, this.state.detail.document)} className="btn btn-outline-success mr-1" type="button"><i className="fa fa-download"></i></button>
                        </div>
                    </div>
                </div>
              </ModalBody>
            </Modal>
        )
    }

    render() {
        const dataCount = this.state.dataIntership.length;
        const columns = [
            {
                Header: 'Nama',
                accessor: 'nama_lengkap',
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["nama_lengkap"] }),
                filterAll: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["email"] }),
                filterAll: true
            },
            {
                Header: 'No. Whatsapp',
                accessor: 'no_wa',
                Cell: (row) => ( 
                    row.original.no_wa.substring(0,2) == '62' ?
                    <a href={'https://wa.me/'+row.original.no_wa} rel='noopener noreferrer' target='_blank'>{row.original.no_wa}</a>
                    :
                    <a href={'https://wa.me/'+'62'+row.original.no_wa.substring(1)} rel='noopener noreferrer' target='_blank'>{row.original.no_wa}</a>
                ),
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["no_wa"] }),
                filterAll: true
            },
            {
                Header: 'Propinsi',
                accessor: 'provinsi.nama_propinsi',
                filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["provinsi.nama_propinsi"] }),
                filterAll: true
            },
            {
                Header: 'Recomender',
                accessor: 'consultant.nama',
                filterable: false
            },
            {
                Header: 'Sekolah / Kampus',
                accessor: 'sekolah.nama_sekolah',
                filterable: false
            },
            {
                Header: 'Posisi Magang',
                accessor: 'posisi_magang',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {
                            row.original.posisi_magang == 1 ?
                            'Culinary'
                            :
                            row.original.posisi_magang == 2 ?
                            'F&B Rotation'
                            :
                            'Room Division'
                        }
                    </div>
                ),
            },
            {
                Header: 'Link',
                accessor: 'link',
                filterable: false,
                Cell: (row) => (
                    <div>
                        <a href={row.original.link} target='_blank'>{row.original.link}</a>
                    </div>
                ),
            },
            {
                Header: 'Tanggal Daftar',
                accessor: 'date_created',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {Moment(row.original.date_created).format('DD MMMM YYYY')}
                    </div>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterable: false,
                Cell: (row) => (
                    <div style={{textAlign:'center'}}>
                        {
                            row.original.status == 1 ? 
                            <span className={'badge bg-secondary'}>Follow Up</span>
                            :
                            row.original.status == 2 ? 
                            <span className={'badge bg-info'}>Progress</span>
                            :
                            row.original.status == 3 ? 
                            <span className={'badge bg-success'}>Document</span>
                            :
                            row.original.status == 4 ? 
                            <span className={'badge bg-danger'}>Cancel</span>
                            :
                            <span className={'badge bg-primary'}>New</span>
                        }
                    </div>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.downloadRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-file" style={{ width: 35, fontSize: 16, padding: 11, color: 'blue' }}></i>{row.id}</button>
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <Breadcrumb title="Intership" />
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="mb-0">Total Registration {dataCount}</h5>
                                        <div className="text-right">
                                            <a href='https://prioritas.niecindonesia.com/' rel="noopener noreferrer" target='_blank'><button className='btn btn-primary'>Landing Page</button></a>
                                        </div>
                                        <br/>
                                        {
                                            this.state.dataIntership.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataIntership}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={100}
                                                className="-striped -highlight"
                                             /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(StudyTourList));