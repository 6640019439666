import React from 'react';
import Tree from 'react-d3-tree';
import Arnantyo from '../../assets/images/organization/1. Arnan.png';
import Priscilla from '../../assets/images/organization/2. Priscilla.png';
import Nasaruddin from '../../assets/images/organization/3. Nasaruddin.png';
import Rendy from '../../assets/images/organization/6. Rendy.png';
import Vivi from '../../assets/images/organization/6. Vivi.png';
import Rosmina from '../../assets/images/organization/6. Ros.png';
import Paul from '../../assets/images/organization/6. Paul.png';
import Ani from '../../assets/images/organization/6. Ani.png';
import Niken from '../../assets/images/organization/6. Niken.png';
import Pande from '../../assets/images/organization/7. profil.png';
import Rila from '../../assets/images/organization/7. profil.png';
import Stefani from '../../assets/images/organization/7. profil.png';
import Augie from '../../assets/images/organization/5. Augie.png';
import Windri from '../../assets/images/organization/4. Windri.png';
import Ridho from '../../assets/images/organization/4. Ridho.png';
import Gunawan from '../../assets/images/organization/6. Gunawan.png';
import Ruth from '../../assets/images/organization/6. Ruth.png';
import Nadiyah from '../../assets/images/organization/7. profil.png';
import Ardiansyah from '../../assets/images/organization/4. Ardiansyah.png';
import Rizky from '../../assets/images/organization/6. Rizky.png';
import Meishy from '../../assets/images/organization/6. Meishy.png';
import Rarie from '../../assets/images/organization/7. profil.png';
import Rio from '../../assets/images/organization/7. profil.png';
import Tio from '../../assets/images/organization/7. profil.png';
import Darius from '../../assets/images/organization/4. Darius.png';
import Shabrina from '../../assets/images/organization/6. Shabrina.png';
import Yudhis from '../../assets/images/organization/7. profil.png';
import Mimi from '../../assets/images/organization/7. profil.png';
import Viky from '../../assets/images/organization/4. Viky.png';
import Nurul from '../../assets/images/organization/5. Nurul.png';
import Fellicia from '../../assets/images/organization/6. Fellicia.png';
import Adila from '../../assets/images/organization/7. profil.png';
import Adinda from '../../assets/images/organization/7. profil.png';
import Cinta from '../../assets/images/organization/7. profil.png';
import TBA from '../../assets/images/organization/7. profil.png';
import Leny from '../../assets/images/organization/3. Leny.png';
import Natha from '../../assets/images/organization/6. Natha.png';
import Abdi from '../../assets/images/organization/3. Abdi.png';
import Wahyu from '../../assets/images/organization/5. Wahyu.png';
import Michael from '../../assets/images/organization/6. Michael.png';
import Pramulia from '../../assets/images/organization/4. Pramulia.png';
import Rachmadi from '../../assets/images/organization/5. Rachmadi.png';
import Nadila from '../../assets/images/organization/6. Nadila.png';
import Effendy from '../../assets/images/organization/4. Effendy.png';
import Ode from '../../assets/images/organization/5. Ode.png';
import Gandhi from '../../assets/images/organization/6. Gandhi.png';
import Erika from '../../assets/images/organization/6. Erika.png';


const DataTableOrganisasi = () => {
  const orgData = {
    name: 'Arnantyo Naresyworo',
    attributes: {
      jabatan: 'CEO & Founder',
      foto: Arnantyo,
    },
    children: [
      {
        name: 'Priscilla Chrisanita',
        attributes: { 
          jabatan: 'Managing Director', 
          foto: Priscilla,
        },
        children: [
          { name: 'Nasaruddin', 
            attributes: { 
              jabatan: 'Country Director', 
              foto: Nasaruddin
            },
            children: [
              { empty: true, children: [
                { empty: true, children: [
                  {
                    name: 'Rendy Pahlevi',
                    attributes: { jabatan: 'Education Consultant', foto: Rendy},
                    }
                ] },
              ] },
              { empty: true, children: [
                { empty: true, children: [
                  {
                    name: 'Vivi Ekawati',
                    attributes: {jabatan: 'Education Consultant', foto: Vivi },
                  }, 
                ]},
              ]},

              { empty: true, children: [
                { empty: true, children: [
                  {
                    name: 'Rosmina',
                    attributes: {jabatan: 'Education Consultant', foto: Rosmina },
                  },
                ]},
              ]},
              { empty: true, children: [
                { empty: true, children: [
                  {
                    name: 'Paulina',
                    attributes: { jabatan: 'Education Consultant', foto: Paul },
                  }, 
                ]},
              ]},

              { empty: true, children: [
                { empty: true, children: [
                  {
                    name: 'Niken',
                    attributes: { jabatan: 'Visa Admission Staff', foto: Niken },
                  },
                ]},
              ]},              
              { empty: true, children: [
                { empty: true, children: [
                  {
                    name: 'Pande',
                    attributes: { jabatan: 'Visa Admission Staff', foto: Pande },
                  }, 
                ]},
              ]},      
              { empty: true, children: [
                { empty: true, children: [
                  {
                    name: 'Rila',
                    attributes: { jabatan: 'Sales Admin', foto: Rila },
                  }, 
                ]},
              ]},
              { empty: true, children: [
                { empty: true, children: [
                  {
                    name: 'Ani',
                    attributes: { jabatan: 'Management Trainee', foto: Ani },
                  },
                ]},
              ]},  
              { empty: true, children: [
                { empty: true, children: [
                  {
                    name: 'Stefani',
                    attributes: { jabatan: 'English Teacher', foto: Stefani },
                  }
                ]},
              ]},  
              { empty: true, children: [
                {
                  name: 'Augie Permata',
                  attributes: { jabatan: 'Senior Education Consultant', foto: Augie }
                },
              ]},   
              {
                name: 'Windri S.',
                attributes: { jabatan: 'Asst. Business Development Manager', foto: Windri },
              },
              {
                name: 'Ridho Awaluddin',
                attributes: { jabatan: 'Branch Manager Medan', foto: Ridho },
                children: [
                  { empty: true, children: [
                    {
                      name: 'Gunawan',
                      attributes: { jabatan: 'Education Consultant', foto: Gunawan },
                    },
                  ]},
                  { empty: true, children: [
                    {
                      name: 'Ruth Miranda',
                      attributes: { jabatan: 'Education Consultant', foto: Ruth },
                    },
                  ]},
                  { empty: true, children: [
                    {
                      name: 'Nadiyah',
                      attributes: { jabatan: 'Bussiness Development Staff', foto: Nadiyah },
                    },
                  ]},
                ]
              },
              {
                name: 'Ardiansyah',
                attributes: { jabatan: 'Branch Manager Makassar', foto: Ardiansyah },
                children: [
                  { empty: true, children: [
                    {
                      name: 'Rizky. M',
                      attributes: { jabatan: 'Education Consultant', foto: Rizky},
                    },
                  ]},
                  { empty: true, children: [
                    {
                      name: 'Meishy Istora',
                      attributes: { jabatan: 'Education Consultant', foto: Meishy },
                    },
                  ]},
                ]
              },
              {
                name: 'Rarie',
                attributes: { jabatan: 'Branch Manager Surabaya', foto: Rarie },
                children: [
                  { empty: true, children: [
                    {
                      name: 'Rio',
                      attributes: { jabatan: 'Education Consultant', foto: Rio },
                    },
                  ]},
                  { empty: true, children: [
                    {
                      name: 'Tio',
                      attributes: { jabatan: 'Education Consultant', foto: Tio },
                    }, 
                  ]},       
                ]
              },
              {
                name: 'Darius Letardo',
                attributes: { jabatan: 'Branch Manager Yogyakarta', foto: Darius },
                children: [
                  { empty: true, children: [
                    {
                      name: 'C. Shabrina',
                      attributes: { jabatan: 'Education Consultant', foto: Shabrina },
                    },
                  ]},
                  { empty: true, children: [
                    {
                      name: 'Yudhistira',
                      attributes: { jabatan: 'Education Consultant', foto: Yudhis },
                    }, 
                  ]},
                  { empty: true, children: [
                    {
                      name: 'Mimi',
                      attributes: { jabatan: 'Business Development Staff', foto: Mimi },
                    },
                  ]},
                ]
              },
              
                {
                  name: 'Viky Ramdani',
                  attributes: { jabatan: 'Branch Manager Jakarta', foto: Viky },
                  children: [
                    {
                      name: 'Nurul',
                      attributes: { jabatan: 'Senior Education Consultant', foto: Nurul },
                      children: [], // Jika Nurul tidak punya anak, kosongkan array ini
                    },
                    { empty: true, children: [
                      {
                        name: 'Fellicia',
                        attributes: { jabatan: 'Education Consultant', foto: Fellicia },
                      },
                    ]},
                    { empty: true, children: [
                      {
                        name: 'Adila',
                        attributes: { jabatan: 'Education Consultant', foto: Adila },
                      },
                    ]},
                    { empty: true, children: [
                      {
                        name: 'Adinda',
                        attributes: { jabatan: 'Education Consultant', foto: Adinda },
                      },
                    ]},
                    { empty: true, children: [
                      {
                        name: 'Cinta',
                        attributes: { jabatan: 'Education Consultant', foto: Cinta },
                      },
                    ]},
                  ],
                },                
              
              {
                name: 'TBA',
                attributes: { jabatan: 'English Teacher Manager', foto: TBA },
              },
            ], 
            
          },
            
          {
            name: 'IGA Leny Savitri',
            attributes: { jabatan: 'Corporate HR', foto: Leny },
            children: [
              { empty: true, children: [
                { empty: true, children: [
                  {
                    name: 'Dimas Angga Natha',
                    attributes: { jabatan: 'HR Assistant', foto: Natha },
                  },
                ]},
              ]},
            ],
          },
          { name: 'Abdi Jaya', 
            attributes: { jabatan: 'Corporate Financial Controller', foto: Abdi },
            children: [
              { empty: true, children: [
                {
                  name: 'Wahyu A.',
                  attributes: { jabatan: 'Accountant Supervisor', foto: Wahyu },
                  children : [
                    {
                      name: 'Michael Jonathan',
                      attributes: { jabatan: 'Accountant', foto: Michael },
                    },
                  ]
                },
              ]},
            ], },
          
          { empty: true, children: [
            { 
              name: 'Pramulia Suliandri', 
              attributes: { jabatan: 'Asst. Project Manager', foto: Pramulia }, 
              children: [
                {
                  name: 'Rachmadi',
                  attributes: { jabatan: 'Full-Stack Developer Supervisor', foto: Rachmadi },
                  children: [
                    { empty: true, children: [
                      {
                        name: 'Nadila',
                        attributes: { jabatan: 'Full-Stack Developer Daily Worker', foto: Nadila },
                      },
                    ]},
                  ]
                },
              ]},
          ]},
          { empty: true, children: [
            { 
              name: 'M. Effendy', 
              attributes: { jabatan: 'Creative Manager', foto: Effendy },
              children:[
                {
                  name: 'WA Ode',
                  attributes: { jabatan: 'Event & Community Supervisor', foto: Ode },
                  children: [
                    {
                      name: 'Gandhi Yuda',
                      attributes: { jabatan: 'Graphic Designer', foto: Gandhi },
                    },
                    {
                      name: 'Erika Rahmadila',
                      attributes: { jabatan: 'Social Media Specialist', foto: Erika },
                    }
                  ]
                },
              ]  
            },
          ]},
          
        ],
      },
    ],
  };

  const containerStyles = {
    width: '1000px',
    height: '700px',
    border: '1px solid #ccc',
    overflow: 'auto',
    position: 'relative',
    margin: '0 auto',
  };
  
  return (
    <div className="w-full">
      <h3 className="mb-4 text-center" style={{ fontFamily:'arial', fontWeight:600 }}>
        NIEC INDONESIA <br /> ORGANIZATION STRUCTURE
      </h3>
      <div style={containerStyles}>
        <Tree
          data={orgData}
          orientation="vertical"
          pathFunc="step"
          translate={{ x: 500, y: 100 }}
          separation={{ siblings: 0.8, nonSiblings: 0.5 }}
          nodeSize={{ x: 330, y: 150 }}
          transform={`scale(1)`}
          renderCustomNodeElement={({ nodeDatum }) => {
            if (nodeDatum.empty) {
              return null;
            }

            return (
              <g>
                <rect
                  width={160}
                  height={120}
                  x={-80}
                  y={-60}
                  fill="#ffffff"
                  stroke="#4B5563"
                  strokeWidth={1.5}
                  rx={10}
                />
                {/* Foto dalam lingkaran */}
                <circle cx="0" cy="-30" r="22" fill="#ffffff" stroke="#4B5563" strokeWidth={1.5} />
                <image
                  href={nodeDatum.attributes?.foto}
                  x={-20}
                  y={-50}
                  width={40}
                  height={40}
                  preserveAspectRatio="xMidYMid slice"
                  clipPath="circle(20px at 20px 20px)"
                  style={{ borderRadius: '50%' }}
                />
                {/* Nama */}
                <foreignObject x={-70} y={0} width={140} height={30}>
                  <div
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      color: '#4B5563',
                      textAlign: 'center',
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                    }}
                  >
                    {nodeDatum.name}
                  </div>
                </foreignObject>
                {/* Jabatan */}
                <foreignObject x={-70} y={20} width={140} height={30}>
                  <div
                    style={{
                      fontSize: '12px',
                      fontStyle: 'italic',
                      color: '#4B5563',
                      textAlign: 'center',
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                    }}
                  >
                    {nodeDatum.attributes?.jabatan}
                  </div>
                </foreignObject>
              </g>
            );
          }}
        />
      </div>
    </div>
  );
};

export default DataTableOrganisasi;
