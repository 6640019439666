/* eslint-disable eqeqeq */

import React, { Component } from "react";
import ReactTable from 'react-table';
import axios from 'axios';
import { connect } from 'react-redux'
import { matchSorter } from 'match-sorter';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ExportCSV } from "../../../services/ExportCSV";
import Moment from 'moment';

import API_URL from "../../../ApiUrl";


class ReportStudent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listStudent: [],
            filtered: [],
            dataCategory: [],
            type: '10',
            dataNoted: '',
            code_students: '',
            modalNoted: false,
            modalUpdate: false,
            modalTask: false,
            task_description: '',
            detailStudent: '',
            typeUpdate: '',
            data_update: ''
        }
    }

    componentDidMount() {
        this.loadDataReport();
    }

    loadDataReport() {
        this.setState({ listStudent: [] })
        let formData = new FormData()
        formData.append('type', this.state.type)
        formData.append('code_user', this.props.dataUser.code_users)
        formData.append('code_roles', this.props.dataUser.code_roles)
        axios.post(API_URL.superStudent + '/todo/report-done-admission', formData, {
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                this.setState({ listStudent: response.data.data })
            })
            .catch(error => console.log(error))
    }

    closeModal() {
        this.setState({
            modalNoted: false,
            modalUpdate: false,
            modalTask: false
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.loadDataReport())
    }

    handleChangeData = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleInput = (e) => {
        let value = e.target.value
        this.setState({
            data_update: value
        })
    }

    editNoted = (row) => {
        this.setState({
            code_students: row.code_students,
            dataNoted: row.student.progress_log.length > 0 ? row.student.progress_log[0].code : '',
            modalNoted: true,
        })
    }

    updateNoted() {
        let formData = new FormData()
        formData.append('code_students', this.state.code_students)
        formData.append('code', this.state.dataNoted)
        formData.append('code_users', this.props.dataUser.code_users)
        formData.append('code_lpt', 'CMNT')
        axios.post(API_URL.studentUrl + 'progress-log', formData, {
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        })
            .then(() => {
                this.loadDataReport()
                this.closeModal();
            })
    }

    handleAddTask() {
        this.setState({
            disableBtn: true,

        })
        let formData = new FormData()
        formData.append('code_students', this.state.detailStudent.code_students)
        formData.append('description', this.state.task_description)
        axios.post(API_URL.superStudent + '/student/approval-task', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(() => {
                this.closeModal()
                this.setState({
                    disableBtn: false,

                })
                this.loadDataReport()
            })
    }

    updateStudent() {
        let formData = new FormData();
        formData.append(this.state.typeUpdate, this.state.data_update)
        formData.append('code_students', this.state.code_students)
        axios.post(API_URL.superStudent + '/student/update-by-colums', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(() => {
                this.loadDataReport();
                this.closeModal();
            })
    }

    handleApprove(item) {
        axios.get(API_URL.superStudent + '/student/approval-task/' + item.id + '/edit', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(() => {
                this.loadDataReport()
            })
    }

    renderModalNote() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalNoted} toggle={() => this.closeModal()}>
                <ModalHeader toggle={() => this.closeModal()}>Update Note</ModalHeader>
                <ModalBody>
                    <h5 className='mb-3'>Edit Noted</h5>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <textarea rows={3} className='form-control' name='dataNoted' value={this.state.dataNoted} onChange={(e) => this.setState({ dataNoted: e.target.value })}></textarea>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.updateNoted()}>Save</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderModalUpdate() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalUpdate} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>
                    <h5 className='mb-3'>Edit Data</h5>
                </ModalHeader>
                <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <select className="form-control" name="data_update" value={this.state.data_update} onChange={this.handleInput}>
                                    <option key={0} value=''>--Select Category--</option>
                                    {
                                        this.state.dataCategory.map(item => {
                                            return <option key={item.code_students_category} value={item.code_students_category}>{item.category}</option>
                                        })
                                    }
                                </select>

                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={(e) => this.updateStudent()}>Save</Button>
                    <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderModalTask() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalTask} toggle={() => this.closeModal()}>
                <ModalHeader toggle={() => this.closeModal()}>Task </ModalHeader>
                <ModalBody>
                    <h5 className='mb-3'>Tambah Task di {this.state.detailStudent.nama}</h5>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <textarea rows={3} className='form-control' name='task_description' value={this.state.task_description} onChange={this.handleChangeData}></textarea>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.disableBtn} color="primary" onClick={(e) => this.handleAddTask()}>Save</Button>
                    <Button disabled={this.state.disableBtn} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }

    editDetail = (code_students, type) => {
        this.getCategory()
        this.setState({
            typeUpdate: type,
            code_students,
            modalUpdate: true
        })
    }

    getCategory() {
        const url = API_URL.superStudent + '/master/student-category';
        axios.get(url, {
            headers: { 'Accept': 'application/json' }
        })
            .then(response => response.data.data)
            .then((data) => {
                this.setState({ dataCategory: data })
            })
    }

    showModalTask(item) {
        this.setState({
            task_description: '',
            detailStudent: item,
            modalTask: true
        })
    }

    render() {

        const columns = [
            {
                Header: 'Tasks',
                accessor: 'tasks',
                filterable: false,
                width: 400,
                Cell: (row) => (
                    <>
                        <button onClick={() => this.showModalTask(row.original)} className="btn btn-info btn-xs "><i className="fa fa-plus"></i></button>
                        <ul style={{ overflow: 'auto' }} className='row'>
                            {
                                row.original.student.tasks.length > 0 ?
                                    row.original.student.tasks.map((item, index) => (
                                        <li style={{ fontSize: '10px' }} className='col mb-1' key={index}> ({Moment(item.created_at).format('DD/MM/YY')}) {item.description} {item.status == 1 ? <span className={'badge bg-success'}>done</span> : <button onClick={() => this.handleApprove(item)} className='btn btn-danger btn-xs'><i className='fa fa-check'></i></button>}</li>
                                    ))
                                    : <></>
                            }
                        </ul>
                    </>
                ),
            },
            {
                Header: 'Code Student',
                accessor: 'code_students',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["code_students"] }),
                width: 120,
                filterAll: true,
                Cell: (row) => (
                    <>
                        <span onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + row.original.student.token_students)}>{row.original.code_students}</span>
                    </>
                )
            },
            {
                Header: 'Name',
                accessor: 'nama',
                width: 250,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama"] }),
                filterAll: true,
                Cell: (row) => (
                    <>
                        <span className={`${row.original.total_day > 7 && this.state.type == '10' ? 'blink' : ''}`}  >{row.original.nama}</span>
                    </>
                )
            },
            {
                Header: 'Consultant',
                accessor: 'consultant_name',
                width: 120,
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["consultant_name"] }),
                filterAll: true,
                Cell: (row) => (
                    <span>{row.original.consultant_name}</span>
                )
            },
            {
                Header: 'Potensial',
                accessor: 'student.category',
                style: { textAlign: 'center' },
                filterable: false,
                width: 100,
                Cell: (row) => (
                    <div onClick={() => this.editDetail(row.original.code_students, 'code_students_category')}>
                        {
                            row.original.student.category == null ?
                                <span className={'badge bg-dark'}>None</span>
                                :
                                row.original.student.category.code_students_category == 'SC00001' ?
                                    <span className={'badge bg-info'}>{row.original.student.category.category}</span>
                                    :
                                    row.original.student.category.code_students_category == 'SC00002' ?
                                        <span className={'badge bg-success'}>{row.original.student.category.category}</span>
                                        :
                                        row.original.student.category.code_students_category == 'SC00003' ?
                                            <span className={'badge bg-danger'}>{row.original.student.category.category}</span>
                                            :
                                            row.original.student.category.code_students_category == 'SC00004' ?
                                                <span className={'badge bg-warning'}>{row.original.student.category.category}</span> : ''

                        }
                    </div>
                ),
            },
            {
                Header: 'Total day',
                accessor: 'total_day',
                width: 100,
                style: { textAlign: 'center' },
                filterable: false,
                Cell: (row) => (
                    <>
                        <div style={{ fontSize: '11px', color: 'grey' }}>{row.original.total_day} days ago</div>
                    </>
                ),
            }
        ];

        if (this.state.type == '10') {
            columns.push(
                {
                    Header: 'Program',
                    accessor: 'programs',
                    width: 280,
                    filterable: false,
                    Cell: (row) => (

                        row.original.student_program != null ?
                            <span style={{ fontSize: '10px' }} >({row.original.student_program.university_program.university.nama_sekolah}) - {row.original.student_program.university_program.program_name} - ({Moment(row.original.student_program.date_program_start).format('MM/YY')}-{Moment(row.original.student_program.date_program_finish).format('MM/YY')})</span>
                            : ''
                    ),
                },
            )
        }
        return (
            <>
                <div className="col-md-12">
                    <div className='card'>
                        <div className="card-header">
                            <div className="row justify-content-between">
                                <div className="col-md-6">
                                    <h5>Leads Student Admission</h5>
                                </div>
                                <div className="col-md-3">
                                    <ExportCSV csvData={this.state.listStudent} fileName={"data-students"} />
                                </div>
                                <div className="col-md-3">
                                    <select name="type" value={this.state.type} className="form-control" onChange={this.handleChange}>
                                        <option value='10' >Apply Campus</option>
                                        <option value='11' >Lodge Visa</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <ReactTable
                                        data={this.state.listStudent}
                                        filterable
                                        columns={columns}
                                        collapseOnDataChange={false}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                        SubComponent={row => {
                                            return (
                                                <div style={{ padding: "20px" }}>
                                                    <div className='row'>
                                                        <div className='col'>
                                                            <i onClick={e => this.editNoted(row.original)} className="fa fa-pencil" style={{ fontSize: 16, color: "#22af47" }}></i><strong> Notes :</strong> <span>{row.original.student.progress_log.length > 0 ?
                                                                <>
                                                                    {row.original.student.progress_log[0].code}
                                                                </>
                                                                : ''}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.renderModalNote()}
                {this.renderModalUpdate()}
                {this.renderModalTask()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser
})

export default connect(reduxState, null)(ReportStudent)
