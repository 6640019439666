/* eslint-disable eqeqeq, no-unused-vars */

import React, { Fragment, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../../actions/login.action';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { matchSorter } from 'match-sorter';
import { Typeahead } from 'react-bootstrap-typeahead';

class DatatableDetailPembayaran extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editingRow: null,
            deletingRow: null,
            model: {
                id_detail_pembayaran: "",
                nama_detail_pembayaran: "",
                status_refund: "",
                status_currency: "",
            },
            errors: {},
            titleList: this.props.title,
            title: this.props.title,
            operation: '',
            toastId: null,
            modal: false,
            modalDelete: false,
            myData: this.props.myData,
            btnDisabled: false,
            logName: '',
            listCountry: [],
            listMataUang: []
        }
    }

    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    componentDidMount() {
        this.setState({
            data: this.props.myData
        });
    }

    resetState() {
        this.setState({
            editingRow: null,
            deletingRow: null,
            model: {
                id_detail_pembayaran: "",
                nama_detail_pembayaran: "",
                status_refund: "",
                status_currency: ""
            },
            errors: {},
        });
    }

    // handle on change   
    onChangesetDetailPembayaran = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);
            model.nama_detail_pembayaran = newData;
            return { model };
        }, () => {
            this.validateDetailPembayaran();
        })
    }
    onChangesetStatusRefund = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let model = Object.assign({}, prevState.model);
            model.status_refund = newData;
            return { model };
        }, () => {
            this.validateStatusRefund();
        })
    }
    onChangesetStatusCurrency = (e) => {
        if (e.length > 0) {
            if (e[0].customOption == true) {
                const newData = e[0].status;
                this.setState(prevState => {
                    let model = Object.assign({}, prevState.model);
                    model.status_currency = newData;
                    return { model };
                }, () => {
                    this.validateStatusCurrency();
                })
            } else {
                const newData = e[0].staus_currency;
                this.setState(prevState => {
                    let model = Object.assign({}, prevState.model);
                    model.status_currency = newData;
                    return { model };
                }, () => {
                    this.validateStatusCurrency();
                })
            }
        } else {
            console.log('hello')
        }
    }
    // end handle on change

    // validasi submit form
    handleValidation() {
        let formIsValid = true;
        if(!this.state.errors.nama_detail_pembayaranStatus){
            formIsValid = false;
        }
        return formIsValid;
    }
    // validasi submit form

    // handle validation per field
    validateDetailPembayaran = () => {
        const data = this.state.model;
        if(data.nama_detail_pembayaran === "" || data.nama_detail_pembayaran === 0 || data.nama_detail_pembayaran === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);
                errors.nama_detail_pembayaran = 'Tipe Pembayaran Harus Diisi';
                errors.nama_detail_pembayaranStatus = false;
                return { errors };
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);
                errors.nama_detail_pembayaran = '';
                errors.nama_detail_pembayaranStatus = true;
                return { errors };
            })
        }
    }
    validateStatusRefund = () => {
        const data = this.state.model;
        if(data.status_refund === "" || data.status_refund === 0 || data.status_refund === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);
                errors.status_refund = 'Status Refund Harus Diisi';
                errors.status_refundStatus = false;
                return { errors };
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);
                errors.status_refund = '';
                errors.status_refundStatus = true;
                return { errors };
            })
        }
    }

    validateStatusCurrency = () => {
        const data = this.state.model;
        if(data.status_currency === "" || data.status_currency === 0 || data.status_currency === null ){
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);
                errors.status_currency = 'Status Refund Harus Diisi';
                errors.status_currencyStatus = false;
                return { errors };
            })
        } else {
            this.setState(prevState => {
                let errors = Object.assign({}, prevState.errors);
                errors.status_currency = '';
                errors.status_currencyStatus = true;
                return { errors };
            })
        }
    }
    // end handle validation per field


    addRow() {
        this.resetState();
        this.setState({
            title: 'Add Detail Pembayaran',
            operation: 'add',
            modal: true,
            logName: 'ADD-DETAIL-PEMBAYARAN'
        });
    }

    editRow(row) {
        this.setState({
            editingRow: row,
            model: Object.assign({}, row),
            title: 'Edit Detail Pembayaran',
            operation: 'edit',
            modal: true,
            logName: 'EDIT-DETAIL-PEMBAYARAN'
        });
    }

    deleteRow(row) {
        this.setState({
            deletingRow: row,
            model: Object.assign({}, row),
            title: 'Delete Detail Pembayaran',
            operation: 'delete',
            modalDelete: true,
            logName: 'DELETE-DETAIL-PEMBAYARAN'
        });
    }

    saveRow() {
        if (this.handleValidation()) {
            this.sendData();
        } else {
            this.validateDetailPembayaran();
            this.validateStatusRefund();
        }
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });
        let row = Object.assign({}, this.state.editingRow, this.state.model);
        let data = this.state.data.slice();
        let index = data.indexOf(this.state.editingRow);
        data.splice(index, 1, row);

        var formData = new FormData();
        formData.append('id_detail_pembayaran', data[0].id_detail_pembayaran);
        formData.append('nama_detail_pembayaran', data[0].nama_detail_pembayaran);
        formData.append('status_refund', data[0].status_refund);
        formData.append('status_currency', data[0].status_currency);

        let url = '/finance/master-detail';

        if (this.state.operation == 'edit') {
            formData.append('_method', 'PATCH');
            url = '/finance/master-detail/' + data[0].id_detail_pembayaran
        } else if (this.state.operation == 'delete') {
            formData.append('_method', 'DELETE');
            url = '/finance/master-detail/' + data[0].id_detail_pembayaran
        }

        axios.post(API_URL.superStudent + url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then((reply) => {
            if (reply.status === 200) {
                if (this.state.operation == "add") {
                    toast.success("Save Sucsessfully");
                } else {
                    toast.success("Update Sucsessfully");
                }
                window.location.reload(false);
            }
        }).catch(function (reply) {
            console.log(reply)
        });
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false
        });
        this.resetState();
    }

    renderDialog() {
        const row = this.state.model;
        const { title } = this.state;
        const modal1 = this.state.modal;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal1} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Tipe Pembayaran</label>
                                    <input className="form-control" name="nama_detail_pembayaran" type="text" id="nama_detail_pembayaran" value={row.nama_detail_pembayaran}
                                        onChange={(e) => this.onChangesetDetailPembayaran(e)}
                                        onBlur={(e) => this.validateDetailPembayaran}
                                        ref={e => this.inputDetailPembayaran = e}
                                    />
                                    <span>{this.state.errors.nama_detail_pembayaran}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Status Refund</label>
                                    <select md="3" className="form-control" name="staus_refund" id="staus_refund" value={row.status_refund}
                                        onChange={(e) => this.onChangesetStatusRefund(e)}
                                        onBlur={(e) => this.validateStatusRefund}
                                        ref={e => this.inputStatusRefund = e}>
                                        <option value="x">--Choose Status Refund--</option>
                                        <option value="0">Non Refund</option>
                                        <option value="1">Refund</option>
                                    </select>
                                    <span>{this.state.errors.status_refund}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Currency</label>
                                    <Typeahead
                                        id="custom-typeahead"
                                        allowNew
                                        multiple
                                        labelKey="status"
                                        newSelectionPrefix="Add a new currency: "
                                        options={[{ 'status': 'Non IDR', 'staus_currency': '0' }, { 'status': 'IDR', 'staus_currency': '1' }]}
                                        placeholder="Currency"
                                        onChange={(e) => this.onChangesetStatusCurrency(e)}
                                        onBlur={(e) => this.validateStatusCurrency}
                                        value={row.status_currency}
                                    />
                                    <span>{this.state.errors.status_currency}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const row = this.state.data;
        const { title } = this.state;
        const modal2 = this.state.modalDelete;
        return (
            <Modal isOpen={modal2} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        const { pageSize, multiSelectOption, myData } = this.props;
        const columns = [
            {
                Header: 'Detail Pembayaran',
                accessor: 'nama_detail_pembayaran',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama_detail_pembayaran"] }),
                filterAll: true
            },
            {
                Header: 'Status Refund',
                accessor: 'status_refund',
                Cell: (row) => (
                    <div>
                        {row.original.status_refund == 0 ? 'Non Refund' : 'Refund'}
                    </div>
                ),
            },
            {
                Header: 'Currency',
                accessor: 'status_currency',
                Cell: (row) => (
                    <div>
                        { 
                            row.original.status_currency == 0 ? 
                            'Non IDR'
                            :
                            row.original.status_currency == 1 ?
                            'IDR'
                            :
                            row.original.status_currency
                        }
                    </div>
                ),
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{ border: 'none' }} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{ border: 'none' }} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <div>
                    <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{ border: 'none' }} >
                        <div>
                            <span>
                                <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                            </span>
                        </div>
                    </button>
                </div>
                <ReactTable
                    data={myData}
                    columns={columns}
                    pageSize={pageSize}
                    showPagination={false}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                    style={{ height: "400px" }}
                />
                <ToastContainer />
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
    codeUsers: state.Login.detailUser.code_users,
    dataId: state.Login.detailUser.id_users,
    dataToken: state.Login.lgnInitialize,
})

const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(DatatableDetailPembayaran);