/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import API_URL from "../../../ApiUrl";
import { connect } from "react-redux";

const TotalCuti = ({ dataToken }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);

  const loadSisaCuti = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_URL.superStudent}/cuti/total-cuti`,
        {
          headers: { Authorization: `Bearer ${dataToken}` },
        }
      );
      const sortedData = (response.data.data || []).sort((a, b) => {
        const totalCutiA = (a.tahunan || 0) + (a.duration || 0);
        const totalCutiB = (b.tahunan || 0) + (b.duration || 0);
        return totalCutiB - totalCutiA; // Urutkan dari terbesar ke terkecil
      });
      setData(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadSisaCuti();
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(data.length / itemPerPage);
  const currentCuti = data.slice(
    (currentPage - 1) * itemPerPage,
    currentPage * itemPerPage
  );

  return (
    <div className="col-md-12">
      <div className="card shadow-sm">
        <div className="card-header bg-success text-white">
          <h6 className="mb-0 text-center">TOTAL CUTI</h6>
        </div>
        <div
          className="card-body"
          style={{ fontSize: "0.9rem", minHeight: "437px" }}
        >
          {isLoading ? (
            <p>Loading data...</p>
          ) : data.length === 0 ? (
            <p className="text-gray-500">No data available.</p>
          ) : (
            <table className="table table-hover table-sm">
              <thead className="thead-light">
                <tr>
                  <th>Nama</th>
                  <th>Cuti</th>
                  <th>Day Payment</th>
                  <th>Total Cuti</th>
                </tr>
              </thead>
              <tbody>
                {currentCuti.map((item, index) => {
                  const { nama = "-", tahunan = 0, duration = 0 } = item;
                  return (
                    <tr key={index}>
                      <td>{nama}</td>
                      <td>{`${tahunan} hari`}</td>
                      <td>{`${duration} hari`}</td>
                      <td>{`${tahunan + duration} hari`}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <nav className="mt-3">
            <ul className="pagination justify-content-center">
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index}
                  className={`page-item ${
                    currentPage === index + 1 ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link btn-sm"
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

TotalCuti.propTypes = {
  dataToken: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  dataToken: state.Login.lgnInitialize,
});

export default connect(mapStateToProps)(TotalCuti);
