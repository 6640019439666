/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import API_URL from "../../../ApiUrl";
import { connect } from "react-redux";
import Moment from "moment";

const TrainingList = ({ dataToken }) => {
  const [data, setData] = useState([]);
  const [average, setAverage] = useState(0); // State untuk menyimpan rata-rata
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(10);
  const [selectedMonth, setSelectedMonth] = useState(Moment().format("YYYY-MM"));

  const loadTrainingList = async () => {
    setIsLoading(true);
    try {
      const startOfMonth = Moment(selectedMonth).startOf("month").format("YYYY-MM-DD");
      const endOfMonth = Moment(selectedMonth).endOf("month").format("YYYY-MM-DD");

      const response = await axios.get(
        `${API_URL.superStudent}/training-list/total-hour`,
        {
          headers: { Authorization: `Bearer ${dataToken}` },
          params: {
            start_date: startOfMonth,
            end_date: endOfMonth,
          },
        }
      );

      const sortedData = (response.data || []).sort((a, b) => {
        const dateA = new Date(a.training_date);
        const dateB = new Date(b.training_date);
        return dateB - dateA;
      });

      setData(sortedData);

      // Hitung rata-rata length_of_training
      const totalLength = sortedData.reduce((sum, item) => sum + (item.length_of_training || 0), 0);
      const totalConsultants = sortedData.length;
      setAverage(totalConsultants > 0 ? (totalLength / totalConsultants).toFixed(2) : 0);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadTrainingList();
  }, [selectedMonth]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(data.length / itemPerPage);
  const currentData = data.slice(
    (currentPage - 1) * itemPerPage,
    currentPage * itemPerPage
  );

  return (
    <div className="col-md-12">
      <div className="card shadow-sm">
        <div className="card-header bg-primary text-white">
          <h6 className="mb-0 text-center">TRAINING HOURS</h6>
        </div>
        <div
          className="card-body"
          style={{ fontSize: "0.9rem", minHeight: "437px" }}
        >
          <div className="row mb-3">
            <div className="col-md-8">
              <input
                type="month"
                className="form-control"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                placeholder="Select Month"
              />
            </div>
          </div>

          {isLoading ? (
            <p>Loading data...</p>
          ) : data.length === 0 ? (
            <p className="text-gray-500">No data available.</p>
          ) : (
            <>
              <table className="table table-hover table-sm">
                <thead className="thead-light">
                  <tr>
                    <th>Nama Lengkap</th>
                    <th>Total Jam Training</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.nama_lengkap || "-"}</td>
                      <td>{item.length_of_training || "-"} jam</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="mt-3">
                <strong>Rata-Rata Jam Training/Konsultan: {average} jam </strong> 
              </div>
            </>
          )}

          <nav className="mt-3">
            <ul className="pagination justify-content-center">
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index}
                  className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
                >
                  <button
                    className="page-link btn-sm"
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

TrainingList.propTypes = {
  dataToken: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  dataToken: state.Login.lgnInitialize,
});

export default connect(mapStateToProps)(TrainingList);