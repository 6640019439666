import React from 'react'
import TotalMemberConsultant from './total-member-consultant'
import TotalTrainingHour from './total-training-hour' 
import TotalCuti from './total-cuti'
import TotalNewCandidate from './total-new-candidate'
import Breadcrumb from "../../common/breadcrumb";
const DashboardHR = () => {
  return(
    <>
      <div className="row">
        <div className="col-lg-12">
        <Breadcrumb parent="Dashboard CEO" title="Dashboard CEO" />
        </div>
      </div>
      <TotalMemberConsultant />
      <div className="row">
        <div className="col-lg-6">
          <TotalTrainingHour />
        </div>
        <div className="col-lg-6">
          <TotalCuti />
        </div>
      </div>
      <TotalNewCandidate />
    </>
  )
}

export default DashboardHR