/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import ReactTable from 'react-table';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import Moment from 'moment';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import CountUp from 'react-countup';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { matchSorter } from 'match-sorter';
import image from '../../assets/images/user/3.jpg'
import Loader from '../common/loader'
import { UncontrolledTooltip } from 'reactstrap';
import debounce from 'lodash.debounce';
import * as XLSX from 'xlsx';
import { Typeahead } from 'react-bootstrap-typeahead';

class DataTablesSchoolRep extends Component {
    constructor(props) {
        super(props);
        this.filterColumn = debounce(this.filterColumn.bind(this), 500)
        this.state = {
            listSchoolRep: [],
            filtered: [],
            alert: null,
            show: false,
            showAction: this.props.dataUser.code_roles === 'RL021' ? '' : this.props.dataUser.code_roles === 'RL001' ? '' : this.props.dataUser.code_roles === 'RL026' ? '' : this.props.dataUser.code_roles === 'RL005' ? '' : this.props.dataUser.code_roles === 'RL012' ? '' : this.props.dataUser.code_roles === 'RL003' ? '' : 'none',
            modalPreview: false,
            modalEditDetail: false,
            modalEditDetailProgram: false,
            modalDetailGranted: false,
            modalDetailProgress: false,
            modalDownload: false,
            detailPreview: [],
            id_delete: "",
            showLoading: false,
            urlPreview: [],
            filterCountry: "",
            filterProvider: "",
            filterGroup: "",
            search: "",
            providerType: [],
            educationGroup: [],
            listCountry: [],
            programType: [],
            typeUpdate: "",
            detailUnivercity: [],
            btnDisabled: false,
            last_update: '',
            code_school_rep: "",
            city: "",
            education_group: "",
            type: "",
            start_date: "",
            end_date: "",
            id_program: "",
            program_name: "",
            program_length: "",
            tuition_cost: "",
            filter_params: "",
            type_contract: "",
            per_page: 10,
            universityGranted: [],
            universityStudent: [],
            universityName: '',
            countrycode: '',
            listUniversity: [],
            id:'',
            textBtn:'',
        }
    }

    componentDidMount() {
        this.getDataSchoolRep()
        this.loadProviderType()
        this.getListCountry()
        this.loadEducationGroup()
    }

    loadProviderType = () => {
        axios.get(API_URL.superStudent + '/master/provider-type', {
            headers: {
                Authorization: `Bearer ` + this.props.dataToken
            }
        })
            .then(response => this.setState({ providerType: response.data.data }))
            .catch(error => console.log(error))
    }

    getListCountry() {
        axios.get(API_URL.superStudent + '/master/country-has-university')
            .then(response => this.setState({ listCountry: response.data.data }))
            .catch(error => console.log(error))
    }

    loadProgramType = () => {
        axios.get(API_URL.superStudent + '/master/jenjang-tujuan', {
            headers: { Authorization: `Bearer ` + this.props.dataToken }
        })
            .then(response => this.setState({ programType: response.data.data }))
            .catch(error => console.log(error))
    }

    loadEducationGroup = () => {
        axios.get(API_URL.superStudent + '/master/education-group', {
            headers: { Authorization: `Bearer ` + this.props.dataToken }
        })
            .then(response => { this.setState({ educationGroup: response.data.data }) })
            .catch(error => console.log(error))
    }

    getDataSchoolRep() {
        this.setState({ showLoading: true })
        axios.get(API_URL.superStudent + `/universities?size=${this.state.per_page}&programs=yes&published=yes&studentCount=yes&provider=yes&search_program=${this.state.search + this.state.filter_params}`, {
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                this.setState({
                    listSchoolRep: response.data.data,
                    showLoading: false
                }) 
            })
    }

    downloadExcel = () => {
        const rows = [];

        this.state.listSchoolRep.forEach((item) => {
            rows.push({
                UniversityName: item.name,
                UniversityCountry: item.country.name,
                UniversityCity: item.city,
                UniversityType: item.provider != null ? item.provider.provider_name : ''
            })
        });

        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        XLSX.writeFile(workbook, 'data-list-university.xlsx');
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleChangeContractDate = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (this.state.type_contract == 'unlimited') {
                this.setState({
                    start_date: 'Unlimited',
                    end_date: 'Unlimited'
                })
            } else {
                this.setState({
                    start_date: this.state.detailUnivercity.start_date,
                    end_date: this.state.detailUnivercity.end_date,
                })
            }
        });
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.getDataSchoolRep())
    }

    handleViewFile = (row) => {
        this.setState({
            modalView: true,
            urlPreview: row.resources
        })
    }

    hideAlert = () => {
        this.setState({
            show: false,
            id_delete: ''
        });
    }

    handleDetail(e, row) {
        this.setState({
            detailPreview: row,
            modalPreview: true
        })
    }

    detailGranted = (row) => {
        this.setState({
            universityGranted: []
        })

        var formData = new FormData();
        formData.append('id_school_rep', row.id);

        axios.post(API_URL.superStudent + '/student-granted-university', formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ` + this.props.dataToken
            }
        })
            .then(response => {
                this.setState({
                    universityGranted: response.data.data,
                    universityName: row.name,
                    modalDetailGranted: true
                })
            })
            .catch(error => console.log(error))
    }

    detailProgress = (row) => {
        this.setState({
            universityProgress: []
        })

        var formData = new FormData();
        formData.append('id_school_rep', row.id);

        axios.post(API_URL.superStudent + '/student-progress-university', formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ` + this.props.dataToken
            }
        })
            .then(response => {
                this.setState({
                    universityProgress: response.data.data,
                    universityName: row.name,
                    modalDetailProgress: true
                })
            })
            .catch(error => console.log(error))
    }

    deleteSchoolRep = () => {
        axios.delete(API_URL.superStudent + '/universities/' + this.state.id_delete, {
            headers: {
                Authorization: `Bearer ` + this.props.dataToken
            }
        })
            .then((response) => {
                this.setState({
                    show: false,
                    id_delete: ''
                });
                this.getDataSchoolRep()
                toast.success("Data rep school berhasil dihapus");
            })
            .catch(err => {
                this.setState({
                    show: false,
                    id_delete: ''
                });
                toast.error("Gagal hapus data rep school");
            })
    }

    closePreview() {
        this.setState({ modalPreview: false, modalView: false })
    }

    renderStart = (number) => {
        const row = [];
        for (var i = 0; i < number; i++) {
            row.push(<i style={{ color: 'gold' }} className='fa fa-star' key={i}></i>);
        }
        return row;
    }

    renderStarSelling = (number) => {
        const row = [];
        for (var i = 0; i < number; i++) {
            row.push(<i style={{ color: 'green' }} className='fa fa-dollar' key={i}></i>);
        }
        return row;
    }

    renderPreview() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalPreview} toggle={() => this.closePreview()} size="lg">
                <ModalHeader toggle={() => this.closePreview()}>Preview Data</ModalHeader>
                <ModalBody style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto'
                }}>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label className='f-w-600'>Nama Instansi </label>
                                <input className='form-control' onChange={() => { }} value={this.state.detailPreview.name} />
                            </div>
                            <div className='form-group'>
                                <label className='f-w-600'>Website</label>
                                <input className='form-control' onChange={() => { }} value={this.state.detailPreview.website} />
                            </div>
                            <div className='form-group'>
                                <label className='f-w-600'>Negara</label>
                                <input className='form-control' onChange={() => { }} value={this.state.detailPreview.country != null ? this.state.detailPreview.country.name + ' (' + this.state.detailPreview.country.code + ')' : ''} />
                            </div>
                            <div className='form-group'>
                                <label className='f-w-600'>City</label>
                                <input className='form-control' onChange={() => { }} value={this.state.detailPreview.city} />
                            </div>
                            <div className='form-group'>
                                <label className='f-w-600'>Address</label>
                                <input className='form-control' onChange={() => { }} value={this.state.detailPreview.address} />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label className='f-w-600'>Email </label>
                                <input className='form-control' onChange={() => { }} value={this.state.detailPreview.email} />
                            </div>
                            <div className='form-group'>
                                <label className='f-w-600'>Telepon/WA </label>
                                <input className='form-control' onChange={() => { }} value={this.state.detailPreview.phone} />
                            </div>
                            <div className='form-group'>
                                <label className='f-w-600'>Provinsi </label>
                                <input className='form-control' onChange={() => { }} value={this.state.detailPreview.province} />
                            </div>
                            <div className='form-group'>
                                <label className='f-w-600'>Zip Code </label>
                                <input className='form-control' onChange={() => { }} value={this.state.detailPreview.zip_code} />
                            </div>
                            <div className='form-group'>
                                <label className='f-w-600'>Tanggal Kontrak </label>
                                <div className="input-group">
                                    <input value={this.state.detailPreview.start_date} onChange={() => { }} className="form-control" />
                                    <input value={this.state.detailPreview.end_date} onChange={() => { }} name="end_date" className="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type='button' className='btn btn-default' onClick={() => this.closePreview()}>Close</button>
                </ModalFooter>
            </Modal>
        )

    }

    renderView() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalView} toggle={() => this.closePreview()} size="md">
                <ModalHeader toggle={() => this.closePreview()}>Resource Materi</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    {
                        this.state.urlPreview.map((item, i) => {
                            const url_doc = [{ uri: item.data }]
                            return <div key={i}>
                                <DocViewer
                                    pluginRenderers={DocViewerRenderers}
                                    documents={url_doc}
                                    config={{
                                        header: {
                                            disableHeader: false,
                                            disableFileName: false,
                                            retainURLParams: false
                                        }
                                    }}
                                />
                            </div>
                        })
                    }
                </ModalBody>
            </Modal>
        )
    }

    SetFilterCountry = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterCountry = newData;
            return { filterCountry };
        }, () => {
            this.onFilteredChangeCustom(newData, "country");
        })
    }

    SetFilterProvider = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterProvider = newData;
            return { filterProvider };
        }, () => {
            this.onFilteredChangeCustom(newData, "filter_provider");
        })
    }

    SetFilterGroup = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterGroup = newData;
            return { filterGroup };
        }, () => {
            this.onFilteredChangeCustom(newData, "education_group_id");
        })
    }

    SetFilterUniversity = (e) => {
        this.setState({
            code_school_rep: e.target.value
        }, () => {
            this.getDataSchoolRep()
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;

                    insertNewFilter = 0;
                }
            });
        }
        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
        let params = '';
        filtered.forEach(element => {
            params += `&${element.id}=${element.value}`
        });

        this.setState({ filtered: filtered, filter_params: params });
        this.filterColumn()
    }

    filterColumn() {
        this.getDataSchoolRep()
    }

    handleStatus(data) {
        var formData = new FormData();
        formData.append('id', data.id);
        formData.append('status', data.status);
        axios.post(API_URL.superStudent + '/university-programs-status', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                this.getDataSchoolRep()
            })
            .catch(error => {
                toast.error("Opps,something error");
            })
    }

    handlePage = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.getDataSchoolRep());
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handlePublish = (data) => {
        let is_publish = 1;
        if (data.is_publish === 1)
            is_publish = 0;
        let formData = new FormData();
        formData.append('code_school_rep', data.code);
        formData.append('is_publish', is_publish);
        axios({
            method: 'POST',
            url: API_URL.superStudent + '/set-published',
            data: formData,
            headers: { Authorization: `Bearer ${this.props.dataToken}`, }
        })
            .then(response => {
                this.getDataSchoolRep()
                this.setState({
                    btnSave: 'Save',
                    disableBtn: false
                })
                toast.success("Status University Updated..")
            })

    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter')
            this.getDataSchoolRep()
    }

    formatAngka = (nominal) => {
        return new Intl.NumberFormat().format(nominal);
    }

    closeModal() {
        this.setState({
            modalEditDetail: false,
            modalEditDetailProgram: false,
            modalDetailGranted: false,
            modalDetailProgress: false,
            modalDownload: false,
        })
    }

    editDetail = (row, type) => {
        this.setState({
            code_school_rep: row.code,
            typeUpdate: type,
            detailUnivercity: row,
            last_update: row.last_update != null ? row.last_update : '',
            city: row.city != null ? row.city : '',
            education_group: row.education_group_id != null ? row.education_group_id : '',
            type: row.provider != null ? row.provider.id : '',
            start_date: row.start_date != null ? row.start_date : '',
            end_date: row.end_date != null ? row.end_date : '',
            modalEditDetail: true,
        })
    }

    editDetailProgram = (row, type) => {
        this.setState({
            typeUpdate: type,
            id_program: row.id,
            program_name: row.program_name,
            program_length: row.program_length,
            tuition_cost: row.tuition_cost,
            modalEditDetailProgram: true
        })
    }

    updateUniversity() {
        switch (this.state.typeUpdate) {
            case "City":
                this.handleEditable('city', this.state.city)
                break;
            case "Last Update":
                this.handleEditable('last_update', this.state.last_update)
                break;
            case "Education Group":
                this.handleEditable('education_group_id', this.state.education_group)
                break;
            case "Contract":
                this.handleEditable('start_date', this.state.start_date)
                this.handleEditable('end_date', this.state.end_date)
                break;
            default:
                this.handleEditable('provider_id', this.state.type)
                break;
        }
    }

    updateUniversityProgram() {
        switch (this.state.typeUpdate) {
            case "Program Name":
                this.handleEditableProgram('program_name', this.state.program_name)
                break;
            case "Program Length":
                this.handleEditableProgram('program_length', this.state.program_length)
                break;

            default:
                this.handleEditableProgram('tuition_cost', this.state.tuition_cost)
                break;
        }
    }

    handleEditable(kolom, value) {
        var formData = new FormData();
        formData.append('code_school_rep', this.state.code_school_rep);
        formData.append('columns', kolom);
        formData.append('value', value);

        axios.post(API_URL.superStudent + '/universities-update-row', formData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => {
                this.getDataSchoolRep();
                this.closeModal();
            })
            .catch(error => {
                toast.error('there is an error ' + error)
            })
    }

    handleEditableProgram(kolom, value) {
        var formData = new FormData();
        formData.append('id_program', this.state.id_program);
        formData.append('columns', kolom);
        formData.append('value', value);

        axios.post(API_URL.superStudent + '/universities-program-update-row', formData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
            .then(response => {
                this.getDataSchoolRep();
                this.closeModal();
            })
            .catch(error => {
                toast.error('there is an error ' + error)
            })
    }

    renderModalPotensial() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalEditDetail} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>Edit {this.state.typeUpdate} {this.state.detailUnivercity.name}</ModalHeader>
                <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                {
                                    this.state.typeUpdate == 'Last Update' ?
                                        <input className="form-control" name="last_update" type='date' value={this.state.last_update} onChange={this.handleChange} />
                                        :
                                        this.state.typeUpdate == 'City' ?
                                            <input className="form-control" name="city" value={this.state.city} onChange={this.handleChange} />
                                            :
                                            this.state.typeUpdate == 'Education Group' ?
                                                <select className='form-control' name="education_group" value={this.state.education_group_id} onChange={this.handleChange}>
                                                    {
                                                        this.state.educationGroup.map(item => {
                                                            return <option key={item.id} value={item.id}>{item.name}</option>
                                                        })
                                                    }
                                                </select>
                                                :
                                                this.state.typeUpdate == 'Type' ?
                                                    <select className='form-control' name='type' value={this.state.type} onChange={this.handleChange}>
                                                        {
                                                            this.state.providerType.map(item => {
                                                                return <option key={item.id} value={item.id}>{item.provider_name}</option>
                                                            })
                                                        }
                                                    </select>
                                                    :
                                                    this.state.typeUpdate == 'Contract' ?
                                                        <>
                                                            <Row className='d-flex justify-content-center'>
                                                                <Col lg='6'>
                                                                    <select className='form-control' name='type_contract' onChange={this.handleChangeContractDate}>
                                                                        <option value={''}>--Choose one--</option>
                                                                        <option value={'unlimited'}>Unlimited</option>
                                                                        <option value={'date_range'}>Date Range</option>
                                                                    </select>
                                                                </Col>
                                                            </Row>
                                                            {
                                                                this.state.type_contract == 'date_range' ?
                                                                    <Row className='mt-3'>
                                                                        <Col lg={6}>
                                                                            <label>Start Date</label>
                                                                            <input className='form-control' type='date' name='start_date' value={this.state.start_date} onChange={this.handleChange} />
                                                                        </Col>
                                                                        <Col lg={6}>
                                                                            <label>End Date</label>
                                                                            <input className='form-control' type='date' name='end_date' value={this.state.end_date} onChange={this.handleChange} />
                                                                        </Col>
                                                                    </Row>
                                                                    :
                                                                    ""
                                                            }
                                                        </>
                                                        :
                                                        ""
                                }
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.updateUniversity()}>Save</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderModalSchoolRepProgram() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalEditDetailProgram} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>Edit {this.state.typeUpdate} {this.state.detailUnivercity.name}</ModalHeader>
                <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                {
                                    this.state.typeUpdate == 'Program Name' ?
                                        <input className="form-control" name="program_name" value={this.state.program_name} onChange={this.handleChange} />
                                        :
                                        this.state.typeUpdate == 'Program Length' ?
                                            <input className="form-control" name="program_length" value={this.state.program_length} onChange={this.handleChange} />
                                            :
                                            <input className="form-control" name="tuition_cost" value={this.state.tuition_cost} onChange={this.handleChange} />
                                }
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.updateUniversityProgram()}>Save</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderDetailStudentGranted() {
        const { modalDetailGranted, universityName } = this.state;
        const columns = [
            {
                Header: 'Code Students',
                accessor: 'code_students',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["code_students"] }),
                filterAll: true,
                Cell: (row) => (
                    <div>
                        <button id="UncontrolledTooltipExample" style={{ border: 'none' }} className='btn btn-outline-primary btn-sm btn-pill text-center' onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + row.original.token_students)}>{row.original.code_students}</button>
                    </div>
                ),
            },
            {
                Header: 'Nama Students',
                accessor: 'nama',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["nama"] }),
                filterAll: true,
            },
            {
                Header: 'Granted Date',
                accessor: 'progress_log[0].datetime_progress',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["progress_log[0].datetime_progress"] }),
                filterAll: true,
                Cell: (row) => (
                    <span>{Moment(row.original.progress_log[0].datetime_progress).format('DD MMMM YYYY')}</span>
                )
            },
        ];
        const child_columns = [
            {
                Header: 'Nama Program',
                accessor: 'program_name',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["program_name"] }),
                filterAll: true,
                Cell: (row) => (
                    <div onClick={e => this.editDetailProgram(row.original, "Program Name")}>{row.original.program_name != "" ? <span>{row.original.program_name}</span> : <span>Not Set</span>}</div>
                )
            },
            {
                Header: 'Program Length',
                accessor: 'program_length',
                style: { textAlign: 'center' },
                filterable: true,
                Cell: (row) => (
                    <div onClick={e => this.editDetailProgram(row.original, "Program Length")}>{row.original.program_length != "" ? <span>{row.original.program_length}</span> : <span>0 Month</span>}</div>
                )
            },
            {
                Header: 'Tuition Cost',
                accessor: 'tuition_cost',
                style: { textAlign: 'center' },
                filterable: true,
                Cell: (row) => (
                    <div onClick={e => this.editDetailProgram(row.original, "Tuition Cost")}>{this.formatAngka(row.original.tuition_cost)}</div>
                )
            },
        ]
        return (
            <Modal className="modal-dialog" isOpen={modalDetailGranted} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>Detail Student Granted {universityName}</ModalHeader>
                <ModalBody>
                    <ReactTable
                        data={this.state.universityGranted}
                        columns={columns}
                        filtered={this.state.filtered}
                        filterable
                        onFilteredChange={(filtered, column, value) => {
                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                        }}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                        className="-striped -highlight"
                        showPaginationBottom={true}
                        SubComponent={row => {
                            return (
                                <ReactTable
                                    data={row.original.programs}
                                    columns={child_columns}
                                    filterable
                                    defaultPageSize={5}
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                    defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                />
                            )
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderDetailStudentProgress() {
        const { modalDetailProgress, universityName } = this.state;
        const columns = [
            {
                Header: 'Code Students',
                accessor: 'code_students',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["code_students"] }),
                filterAll: true,
                Cell: (row) => (
                    <div>
                        <button id="UncontrolledTooltipExample" style={{ border: 'none' }} className='btn btn-outline-primary btn-sm btn-pill text-center' onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + row.original.token_students)}>{row.original.code_students}</button>
                    </div>
                ),
            },
            {
                Header: 'Nama Students',
                accessor: 'nama',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["nama"] }),
                filterAll: true,
            },
            {
                Header: 'Input Date',
                accessor: 'input_date',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["input_date"] }),
                filterAll: true,
                Cell: (row) => (
                    <span>{Moment(row.original.input_date).format('DD MMMM YYYY')}</span>
                )
            },
            {
                Header: 'Progress',
                accessor: 'last_progress_log.progress.name_progress',
                style: { textAlign: 'center' },
                filterable: true,
                Cell: (row) => (
                    <div onClick={e => this.editDetailProgram(row.original, "Last Progress")}>{row.original.last_progress_log.progress.name_progress}</div>
                )
            },
        ];
        const child_columns = [
            {
                Header: 'Nama Program',
                accessor: 'program_name',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["program_name"] }),
                filterAll: true,
                Cell: (row) => (
                    <div onClick={e => this.editDetailProgram(row.original, "Program Name")}>{row.original.program_name != "" ? <span>{row.original.program_name}</span> : <span>Not Set</span>}</div>
                )
            },
            {
                Header: 'Program Length',
                accessor: 'program_length',
                style: { textAlign: 'center' },
                filterable: true,
                Cell: (row) => (
                    <div onClick={e => this.editDetailProgram(row.original, "Program Length")}>{row.original.program_length != "" ? <span>{row.original.program_length}</span> : <span>0 Month</span>}</div>
                )
            },
            {
                Header: 'Tuition Cost',
                accessor: 'tuition_cost',
                style: { textAlign: 'center' },
                filterable: true,
                Cell: (row) => (
                    <div onClick={e => this.editDetailProgram(row.original, "Tuition Cost")}>{this.formatAngka(row.original.tuition_cost)}</div>
                )
            },
        ];
        return (
            <Modal className="modal-dialog" isOpen={modalDetailProgress} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>Detail Student Progress {universityName}</ModalHeader>
                <ModalBody>
                    <ReactTable
                        data={this.state.universityProgress}
                        columns={columns}
                        filtered={this.state.filtered}
                        filterable
                        onFilteredChange={(filtered, column, value) => {
                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                        }}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                        className="-striped -highlight"
                        showPaginationBottom={true}
                        SubComponent={row => {
                            return (
                                <ReactTable
                                    data={row.original.programs}
                                    columns={child_columns}
                                    filterable
                                    defaultPageSize={5}
                                    className="-striped -highlight"
                                    showPaginationBottom={true}
                                    defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                />
                            )
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    handleModalDownload = () => {
        this.getDataCampus()
        this.setState({
            textBtn:'Download',
            modalDownload: true
        })
    }

    getDataCampus() {
        const url = API_URL.superStudent + '/universities';
        axios.get(url, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.props.dataToken}`
          }
        })
          .then(response => response.data)
          .then((data) => {
            this.setState({ listUniversity: data.data })
          })
    }


  setKampusTujuan = (e) => {
    const newData = e.length > 0 ? e[0].id : "";

    this.setState(prevState => {
        let id = Object.assign({}, prevState.id);
        id = newData;
        return { id }
    });
  }

    downloadData = () => {
        this.setState({
            textBtn:'Downloading...',
            btnDisabled:true
        })
        axios.get(API_URL.superStudent + '/programs-by-university/'+this.state.id, {
          headers: { 'Authorization': `Bearer ${this.props.dataToken}`}
        })
          .then(response => response.data.data)
          .then((data) => {
            const rows = [];
            data.forEach((item) => {
                rows.push({
                    ProgramName: item.program_name,
                    ProgramLength: item.program_length,
                    ProgramLevel: item.level.jenjang_tujuan,
                    ProgramType: item.bidang.name,
                    TuitionCost: item.tuition_cost+' '+item.university.currency.code_mata_uang,
                    TuitionCategory: item.tuition_category.tuition_payment_category
                });
            });
                
            const worksheet = XLSX.utils.json_to_sheet(rows);
            const workbook = XLSX.utils.book_new();
            
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
            XLSX.writeFile(workbook, 'data-program-university.xlsx');
            this.setState({
                textBtn:'Download',
                btnDisabled:false
            })
            
          })
          .catch(() => {
            this.setState({
                textBtn:'Download',
                btnDisabled:false
            })
          })

    }

    renderModalDownload() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalDownload} toggle={() => this.closeModal()} size="md">
                <ModalHeader toggle={() => this.closeModal()}> Download Data</ModalHeader>
                <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="row">
                            <div className='col-md-12'>
                                <div className='form-group'>
                                    <label className='control-label f-w-600'>University</label>
                                    <Typeahead
                                        id="basic-typeahead"
                                        labelKey="name"
                                        name="id"
                                        options={this.state.listUniversity}
                                        onChange={(e) => this.setKampusTujuan(e)}
                                        placeholder="--Pilihan Kampus--"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.downloadData()}>{this.state.textBtn}</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (row) => (
                    <div className="image-sm-size align-middle">
                        <img className="img-radius img-50 align-top m-r-15 rounded-circle" src={row.original.logo !== null ? row.original.logo : image} alt="" />
                        <div className="d-inline-block">
                            <div className='f-w-600'>{row.original.name}</div>
                            {
                                this.renderStart(row.original.rating)
                            }
                            <br />
                            {
                                this.renderStarSelling(row.original.selling_point)
                            }
                        </div>
                    </div>
                ),
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true,
            },
            {
                Header: 'City',
                accessor: 'city',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["city"] }),
                style: { textAlign: 'center' },
                filterAll: true,
                width: 110,
                Cell: (row) => (
                    <div onClick={e => this.editDetail(row.original, "City")}>{row.original.city}</div>
                )
            },
            {
                Header: 'Country',
                accessor: 'country_code',
                style: { textAlign: 'center' },
                filterable: true,
                width: 110,
                Cell: (row) => (
                    <div onClick={e => this.editDetail(row.original, "Country")}>{row.original.country.name}</div>
                ),
                Filter: () =>
                    <select value={this.state.filterCountry || ''} onChange={(e) => this.SetFilterCountry(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.listCountry.map(item => {
                                return <option key={item.code} value={item.code}>{item.name}</option>
                            })
                        }
                    </select>
            },
            {
                Header: 'Edu Group',
                accessor: 'education_group_id',
                style: { textAlign: 'center' },
                filterable: true,
                width: 110,
                Cell: (row) => (
                    <div onClick={e => this.editDetail(row.original, "Education Group")}>
                        <span>{row.original.education_group != null ? row.original.education_group.name : <span>""</span>}</span>
                    </div>
                ),
                Filter: () =>
                    <select value={this.state.filterGroup || ''} onChange={(e) => this.SetFilterGroup(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.educationGroup.map(item => {
                                return <option key={item.id} value={item.id}>{item.name}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'Type',
                accessor: 'provider_id',
                style: { textAlign: 'center' },
                filterable: true,
                width: 110,
                Cell: (row) => (
                    <div onClick={e => this.editDetail(row.original, "Type")}>
                        <span>{row.original.provider != null ? row.original.provider.provider_name : <span>''</span>}</span>
                    </div>
                ),
                Filter: () =>
                    <select value={this.state.filterProvider || ''} onChange={(e) => this.SetFilterProvider(e)}>
                        <option key={0} value=''>Show All</option>
                        {
                            this.state.providerType.map(item => {
                                return <option key={item.id} value={item.id}>{item.provider_name}</option>
                            })
                        }
                    </select>,
            },
            {
                Header: 'Student Count',
                accessor: 'student_count',
                filterable: false,
                width: 170,
                Cell: (row) => (
                    <div className="row">
                        <div className="col text-center b-r-light" style={{ cursor: 'pointer' }} onClick={() => this.detailGranted(row.original)}>
                            <span>Granted</span>
                            <h6 className="counter mb-0">
                                <CountUp className="counter" end={row.original.student_granted_count} />
                            </h6>
                        </div>
                        <div className="col text-center b-r-light" style={{ cursor: 'pointer' }} onClick={() => this.detailProgress(row.original)}>
                            <span>Progress</span>
                            <h6 className="counter mb-0">
                                <CountUp className="counter" end={row.original.student_progress_count} />
                            </h6>
                        </div>
                    </div>
                )
            },
            {
                Header: 'Contract',
                accessor: 'end_date',
                width: 110,
                style: { textAlign: 'center' },
                filterable: false,
                Cell: (row) => (
                    <>
                        {
                            row.original.education_group_id == 'null' ?
                                <div className='row' onClick={e => this.editDetail(row.original, 'Contract')}>
                                    {
                                        row.original.start_date == 'Unlimited' ?
                                            <span className='col'>{row.original.start_date}</span>
                                            :
                                            <span className='col'>{Moment(row.original.start_date).format('DD MMM YYYY')}</span>
                                    }
                                    <i className='fa fa-arrows-h col'></i>
                                    {
                                        row.original.end_date == 'Unlimited' ?
                                            <span className='col'>{row.original.end_date}</span>
                                            :
                                            <span className='col'>{Moment(row.original.end_date).format('DD MMM YYYY')}</span>
                                    }
                                </div>
                                :
                                <div className='row'>
                                    {
                                        row.original.education_group === null ?
                                            ''
                                            :
                                            <>
                                                {
                                                    row.original.education_group.start_date === null ?
                                                        <span className='col'>Not Available</span>
                                                        :
                                                        <span className='col'>{row.original.education_group.start_date}</span>
                                                }
                                                <i className='fa fa-arrows-h col'></i>
                                                {
                                                    row.original.education_group.start_date === null ?
                                                        <span className='col'>Not Available</span>
                                                        :
                                                        <span className='col'>{row.original.education_group.end_date}</span>
                                                }
                                            </>
                                    }
                                </div>
                        }
                    </>
                ),
            },
            {
                Header: 'Last Update',
                accessor: 'last_update',
                width: 110,
                style: { textAlign: 'center' },
                filterable: false,
                Cell: (row) => (
                    <div className='row' onClick={e => this.editDetail(row.original, 'Last Update')}>
                        <span className='col'>{Moment(row.original.last_update).format('DD MMM YYYY')}</span>
                    </div>
                ),
            },
            {
                Header: 'Status',
                accessor: 'is_publish',
                style: { textAlign: 'center' },
                filterable: false,
                Cell: (row) => (
                    <button onClick={() => this.handlePublish(row.original)} className="btn btn-dark btn-xs" type="button" style={{ border: 'none', display: this.state.showAction }}>
                        {row.original.is_publish === 1 ? 'Active' : 'Inactive'}
                    </button>
                ),

            },
            {
                Header: <b>Action</b>,
                id: 'action',
                accessor: str => 'delete',
                width: 110,
                Cell: (row) => (
                    <>
                        <button onClick={(e) => this.handleDetail(e, row.original)} className="btn btn-outline-warning btn-xs" type="button" style={{ border: 'none', display: this.state.showAction }}>
                            <i className="fa fa-search"></i>{row.id}
                        </button>
                        <button
                            onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/form-school-rep/` + row.original.id)}
                            className="btn btn-outline-success btn-xs" type="button" style={{ border: 'none', display: this.state.showAction }} ><i className="fa fa-pencil"></i>{row.id}
                        </button>
                        <button
                            onClick={() => this.setState({ show: true, id_delete: row.original.id })}
                            className="btn btn-outline-danger btn-xs" type="button" style={{ border: 'none', display: this.state.showAction }} ><i className="fa fa-trash"></i>
                        </button>
                    </>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ];

        const child_columns = [
            {
                Header: 'Nama Program',
                accessor: 'program_name',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["program_name"] }),
                filterAll: true,
                Cell: (row) => (
                    <div onClick={e => this.editDetailProgram(row.original, "Program Name")}>{row.original.program_name != "" ? <span>{row.original.program_name}</span> : <span>Not Set</span>}</div>
                )
            },
            {
                Header: 'Program Length',
                accessor: 'program_length',
                style: { textAlign: 'center' },
                filterable: true,
                Cell: (row) => (
                    <div onClick={e => this.editDetailProgram(row.original, "Program Length")}>{row.original.program_length != "" ? <span>{row.original.program_length}</span> : <span>0 Month</span>}</div>
                )
            },
            {
                Header: 'Tuition Cost',
                accessor: 'tuition_cost',
                style: { textAlign: 'center' },
                filterable: true,
                Cell: (row) => (
                    <div onClick={e => this.editDetailProgram(row.original, "Tuition Cost")}>{this.formatAngka(row.original.tuition_cost)}</div>
                )
            },
            {
                Header: 'Status',
                accessor: 'status',
                style: { textAlign: 'center' },
                Cell: (row) => (
                    row.original.status === 'Active' ?
                        <button style={{ border: 'none', fontSize: 18 }} onClick={() => this.handleStatus(row.original)} className="btn btn-outline-primary btn-xs" id="TooltipTop" data-placement="top"><i className="fa fa-check success-icon"></i><UncontrolledTooltip placement="top" target="TooltipTop">Click to deactivate
                        </UncontrolledTooltip> </button>
                        :
                        <button onClick={() => this.handleStatus(row.original)} style={{ border: 'none', fontSize: 18 }} className="btn btn-outline-primary btn-xs" id="TooltipBottom" data-placement="bottom"><i className="fa fa-remove danger-icon"></i><UncontrolledTooltip placement="bottom" target="TooltipBottom">Click to activate
                        </UncontrolledTooltip> </button>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div className="row">
                        <div className="col-md-3">
                            <button
                                onClick={() => this.props.history.push({
                                    pathname: `${process.env.PUBLIC_URL}/pages/program-university/` + row.original.id,
                                    state: row.original,
                                })}
                                className="btn btn-info btn-pill btn-sm" type="button" style={{ border: 'none' }} >edit
                            </button>
                        </div>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                width: 100,
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <SweetAlert
                    title="Loading..."
                    showConfirm={false}
                    showCancel={false}
                    show={this.state.showLoading}
                />

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Ya, hapus!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Anda yakin?"
                    show={this.state.show}
                    onConfirm={this.deleteSchoolRep}
                    onCancel={this.hideAlert}
                    focusCancelBtn
                />

                {this.state.alert}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <Link className="btn btn-success" style={{ color: 'white', display: this.state.showAction }} to={'/pages/form-school-rep'}> Tambah Data</Link>
                                        </div>
                                        <div className='col-md-6'>
                                            <button onClick={() => this.handleModalDownload()} className='btn btn-success' style={{ float: 'inline-end' }}>Download Excel</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body datatable-react">
                                    <div className='row'>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="search program"
                                                    size="medium"
                                                    name="search"
                                                    value={this.state.search}
                                                    onChange={this.handleInput}
                                                    onKeyDown={this.handleKeyDown}
                                                    label="Search Program"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-2'>
                                            <select name='per_page' value={this.state.per_page} onChange={this.handlePage} className='form-control' >
                                                <option value='20'>20 rows</option>
                                                <option value='50'>50 rows</option>
                                                <option value='100'>100 rows</option>
                                                <option value='200'>200 rows</option>
                                                <option value='500'>500 rows</option>
                                            </select>
                                        </div>
                                    </div>
                                    <ReactTable
                                        data={this.state.listSchoolRep}
                                        pageSize={this.state.per_page}
                                        columns={columns}
                                        filtered={this.state.filtered}
                                        filterable
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                        className="sellers team-members"
                                        showPaginationBottom={false}
                                        SubComponent={row => {
                                            return (
                                                <>
                                                    <Link className="btn btn-success m-3 col-md-2" style={{ color: 'white', display: this.state.showAction }} to={'/pages/program-university/' + row.original.id}> Tambah Data</Link>
                                                    <ReactTable
                                                        data={row.original.programs}
                                                        columns={child_columns}
                                                        filterable
                                                        defaultPageSize={15}
                                                        className="-striped -highlight"
                                                        showPaginationBottom={true}
                                                        defaultFilterMethod={(filter, row) =>
                                                            String(row[filter.id]) === filter.value}
                                                    />
                                                </>
                                            )
                                        }}
                                    />
                                    <Loader showLoading={this.state.showLoading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
                {this.renderPreview()}
                {this.renderView()}
                {this.renderModalPotensial()}
                {this.renderModalSchoolRepProgram()}
                {this.renderDetailStudentGranted()}
                {this.renderDetailStudentProgress()}
                {this.renderModalDownload()}
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser
})

export default connect(reduxState, null)(withRouter(DataTablesSchoolRep));