const superStudent = 'https://development.niecindonesia.com/api/v1/application';
const superStudentV2 = 'https://development.niecindonesia.com/api/v2/application';
const superStudentAuth = 'https://development.niecindonesia.com/api/v1/auth';
const baseSuperStudent = 'https://development.niecindonesia.com/storage';
const eventUrl = 'https://development.niecindonesia.com/event/'
const eventToken = 'eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ'
const studentUrl = 'https://development.niecindonesia.com/student/';
const studentToken = 'jM0NTY3ODkwIiwibmFtZSI6IkpvaG4IiwiaWF0INTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4f';
const coworkingSpace = 'https://development.niecindonesia.com/coworking/';
const coworkingToken = '10936e153cc73190ef8b276661e85b8049f7bff74ed45b3908c4e467c6793e4b2539abc73f0e4fd3c145f327ecc1bbe0300ba10d16386b151c2c9fb271bdee8b';
const placementTest = 'https://development.niecindonesia.com/placement-test/';
const placementTestToken = 'x9UMxaGolsflds3yKHiQ7i04whX1WhLOK0KxIDMz3ThQtvDsFpQgpUwTLRUTXKbXgH';
const biteshipToken = 'biteship_live.eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiUFJPRF9BUElfTUVSQ0hBTkRJU0UiLCJ1c2VySWQiOiI2NTlkMGQ0OTVlNDFlZDM2Yzc4NzQyNTYiLCJpYXQiOjE3MDg5MjkyMTF9.gbsV6AfkmfYBzXz-jJyNFnn6Qdcprk0pAPQxTfJuITc';
 

const API_URL = {
    superStudent,
    superStudentV2,
    superStudentAuth,
    baseSuperStudent,
    eventUrl,
    eventToken,
    studentUrl,
    studentToken,
    coworkingSpace,
    coworkingToken,
    placementTest,
    placementTestToken,
    biteshipToken
}

export default API_URL;
