/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect } from "react";
import API_URL from "../../../ApiUrl";
import axios from 'axios';
import { useSelector } from 'react-redux'
import setRangeDate from '../../../helper/general-function';

const ReportTeacherAttendance = ({date}) => {

    const[heads, setHeaders] = useState([]);
    const[dataTeacher, setDataTeacher] = useState([]);
    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }))

    const loadReportTeacherAttandance = () => {
        let xdata = setRangeDate(date);
        const url = API_URL.superStudent + '/english-course/report-teacher-attandance';
        let formData = new FormData();
        formData.append('start_date', xdata.start)
        formData.append('end_date', xdata.end)
        axios.post(url, formData, {
            headers: { 'Authorization': `Bearer ${dataToken}` }
        }).then(response => response.data.data).then((data) => {
            setDataTeacher(data)
            setHeaders(Object.keys(data[0]))
        });
    }

    useEffect(() => {
        loadReportTeacherAttandance()
    },[date])

    return(
        <>
            <h5>Report Attandance Teacher</h5>
            <div className='table-responsive'>
                <table style={{ fontSize: '10px' }} className='table table-striped table-hover table-bordered'>
                    <thead className='tbl-strip-thad-bdr'>
                        <tr className='table-secondary'>
                            {heads.map((header, index) => (
                                <th key={index} className="border border-gray-400 px-4 py-2">
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dataTeacher.map((row, rowIndex) => (
                            <tr key={rowIndex} className="border border-gray-400">
                            {heads.map((header, colIndex) => (
                                <td key={colIndex} className="border border-gray-400 px-4 py-2">
                                {row[header]}
                                </td>
                            ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );

}

export default ReportTeacherAttendance;