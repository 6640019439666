/* eslint-disable eqeqeq, no-useless-concat */

import React, { Component } from "react"
import ReactTable from 'react-table'
import axios from 'axios'
import { connect } from 'react-redux'
import { matchSorter } from 'match-sorter'
import Loader from "../../common/loader"
import Moment from 'moment'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from "../../../ApiUrl"
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class StudentAlmostDone extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalUpdate: false,
            showLoading: false,
            modalTodo: false,
            modalTask: false,
            code_students: '',
            code_konsultan: '',
            textUpdate: 'Update',
            disableBtn: false,
            leadsData: [],
            listConsultant: [],
            dataCampus:[],
            internalUserList:[],
            dataProgram:[],
            description: '',
            todoType: '',
            due_date: '',
            task_description: '',
            detailStudent: '',
            form:'',
            status_approval:'1',
            code_pilihan_kampus: '',
            university_id: '',
            id_program:'',
            start_date: Moment().startOf('year').format('YYYY-MM-DD'),
            end_date: Moment().month(5).endOf('month').format('YYYY-MM-DD'),
            periode_filter:'this_periode'
        }
    }

    componentDidMount() {
        this.loadDataStudents()
    }

    loadConsultant = () => {
        axios.get(API_URL.superStudent + '/master/consultant/list-active', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` },
        })
            .then(response => this.setState({ listConsultant: response.data.data }))
    }

    editDetail = (code_students) => {
        this.loadConsultant()
        this.setState({
            code_students,
            code_konsultan:'',
            modalUpdate: true
        })
    }

    handleInput = (e) => {
        console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    loadDataStudents() {
        this.setState({ showLoading: true })
        let formData = new FormData()
        formData.append('code_konsultan', this.props.dataUser.code_konsultan)
        formData.append('id_area', this.props.dataUser.id_area)
        formData.append('code_roles', this.props.dataUser.code_roles)
        formData.append('start_date', this.state.start_date)
        formData.append('end_date', this.state.end_date)
        axios.post(API_URL.superStudent + '/student/last-six-month', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => {
                this.setState({ leadsData: response.data.data, showLoading: false })
            })
    }

    updateStudent() {
        let formData = new FormData()
        formData.append('code_students', this.state.code_students)
        formData.append('code_konsultan', this.state.code_konsultan)
        this.setState({
            disableBtn:true,
            textUpdate:'Updating...'
        })
        axios.post(API_URL.superStudent + '/student/set-consultant', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` },
        })
        .then(() => {
            this.setState({
                modalUpdate:false,
                disableBtn:false,
                textUpdate:'Update'
            })
            toast.success('Data successfully updated')
            this.loadDataStudents()
        })
        .catch((error) => {
            this.setState({
                modalUpdate:false,
                disableBtn:false,
                textUpdate:'Update'
            })
            toast.error('Something Wrong')
        })
    }

    saveList() {
        this.setState({
            disableBtn: true
        })
        let formData = new FormData()
        formData.append('code_students', this.state.code_students)
        formData.append('description', this.state.description)
        formData.append('todoType', this.state.todoType)
        formData.append('handle_by', 'CT046')
        formData.append('code_jobdesk', 'JD006')
        formData.append('title', 'Admission Task list');
        formData.append('status_approval', this.state.status_approval)
        formData.append('due_date', this.state.due_date)
        formData.append('from', this.state.from)
        formData.append('code_school_rep', this.state.code_pilihan_kampus);
        formData.append('id_program', this.state.id_program)
        formData.append('status_id', '6')
        axios.post(API_URL.superStudent + '/todo', formData, {
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
            .then(() => {
                toast.success('task list add successfully')
                this.closeModal()
                this.loadDataStudents()
            })
            .catch(() => {
                toast.error('there is an error')
                this.closeModal()
                this.loadDataStudents()
            })
    }

    addTodo = (code_student) => {
        this.loadInternalUser()
        this.getDataCampus()
        this.setState({
            modalTodo: true,
            description: '',
            todoType: '',
            due_date: '',
            disableBtn: false,
            code_students: code_student
        })
    }

    showModalTask(item) {
        this.setState({
            task_description: '',
            detailStudent: item,
            modalTask: true
        })
    }

    closeModal() {
        this.setState({
            modalUpdate: false,
            modalTodo:false,
            modalTask:false
        });
    }

    handleTypeList = (e) => {
        let due_date = '';
        if (e.target.value === '10' || e.target.value === '33') {
            due_date = Moment().add(1, 'days').format('YYYYY-MM-DD HH:mm:ss')
            if (Moment().format('d') == 5 || Moment().format('d') == 6 || Moment().format('d') == 7)
                due_date = Moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD 19:00:00')
        }
        else if (e.target.value === '11') {
            due_date = Moment().add(2, 'days').format('YYYY-MM-DD HH:mm:ss')
            if (Moment().format('d') == 5 || Moment().format('d') == 6 || Moment().format('d') == 7)
                due_date = Moment().add(1, 'weeks').startOf('isoWeek').day(2).format('YYYY-MM-DD 19:00:00')
            else if (Moment().format('d') == 4)
                due_date = Moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD 19:00:00')
        }
        this.setState({
            due_date,
            todoType: e.target.value
        })
    }

    handleFrom = (e) => {
        let value = e.target.value
        let status_approval = '1'
        if (value !== this.props.dataUser.code_users) {
            status_approval = '0'
        }
        this.setState({
            status_approval,
            from: e.target.value
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleAddTask() {
        this.setState({
            disableBtn: true,

        })
        let formData = new FormData()
        formData.append('code_students', this.state.detailStudent.code_students)
        formData.append('description', this.state.task_description)
        axios.post(API_URL.superStudent + '/student/approval-task', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(() => {
                this.closeModal()
                this.setState({
                    disableBtn: false,

                })
                this.loadDataStudents()
            })
    }

    handlePeriode = (e) => {
        let value = e.target.value
        if(value === 'this_periode'){
            this.setState({
                start_date: Moment().startOf('year').format('YYYY-MM-DD'),
                end_date: Moment().month(5).endOf('month').format('YYYY-MM-DD'),
                periode_filter:value
            },() => this.loadDataStudents())
        }
        else{
            this.setState({
                start_date:Moment().month(6).startOf('month').format('YYYY-MM-DD'),
                end_date: Moment().endOf('year').format('YYYY-MM-DD'),
                periode_filter:value
            }, () => this.loadDataStudents())
        }
    }

    renderModalUpdate() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalUpdate} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>
                    <h5 className='mb-3'>Update Data</h5>
                </ModalHeader>
                <ModalBody>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <div className="form-group">
                                    <select className="form-control" onChange={this.handleInput} name="code_konsultan" value={this.state.code_konsultan}>
                                        <option>--Select Konsultan--</option>
                                        {this.state.listConsultant.length > 0 ?
                                            this.state.listConsultant.map((item, index) => (
                                                <option key={index} value={item.code}>{item.name}</option>
                                            )) : ''}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.disableBtn} color="primary" onClick={(e) => this.updateStudent()}>Save</Button>
                    <Button disabled={this.state.disableBtn} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderTodoModal() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalTodo} toggle={() => this.closeModal()} size="md">
                <ModalHeader toggle={() => this.closeModal()}>Add Data</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                    <form className="needs-validation tooltip-validation" noValidate="" >
                        <div className='form-group'>
                            <label className='control-label'>Description</label>
                            <textarea className='form-control' onChange={this.handleChange} value={this.state.description} name='description'></textarea>
                        </div>
                        <div className='form-group'>
                            <label className='control-label'>Type</label>
                            <select onChange={this.handleTypeList} className='form-control' value={this.state.todoType} name='todoType'>
                                <option key={0} value='0'>Select Task</option>
                                <option key={1} value='10'>Pendaftaran Sekolah</option>
                                <option key={2} value='11'>Lodge Visa</option>
                                <option key={3} value='33'>Accomodation</option>
                                <option key={4} value='999'>Others</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <label className='control-label'>University</label>
                            <Typeahead
                                id="basic-typeahead"
                                labelKey="name"
                                name="code_pilihan_kampus"
                                options={this.state.dataCampus}
                                onChange={(e) => this.setKampusTujuan(e)}
                                placeholder="--Pilihan Kampus--"
                            />
                        </div>
                        <div className='form-group'>
                            <label className='control-label'>Program</label>
                            <Typeahead
                                id="basic-typeahead"
                                labelKey="program_name"
                                name="id_program"
                                options={this.state.dataProgram}
                                onChange={(e) => this.setProgramTujuan(e)}
                                placeholder="--Pilihan Program--"
                            />
                        </div>
                        <div className='form-group'>
                            <label className='control-label'>From</label>
                            <select name='from' className='form-control' onChange={this.handleFrom} value={this.state.from}>
                                <option key={0} value=''>--Select--</option>
                                {
                                    this.state.internalUserList.map(list => (
                                        <option key={list.code_users} value={list.code_users}>{list.username}</option>
                                    ))
                                }
                            </select>
                            <span style={{ fontSize: '10px', color: 'red' }}>Task perlu di approve yang bersangkuran ketika user yang  di pilih tidak sama dengan user yang login</span>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveList()}>Save</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderModalTask() {
        return (
            <Modal className="modal-dialog" isOpen={this.state.modalTask} toggle={() => this.closeModal()}>
                <ModalHeader toggle={() => this.closeModal()}>Task </ModalHeader>
                <ModalBody>
                    <h5 className='mb-3'>Tambah Task di {this.state.detailStudent.nama}</h5>
                    <form className="needs-validation" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <textarea rows={3} className='form-control' name='task_description' value={this.state.task_description} onChange={this.handleChange}></textarea>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.disableBtn} color="primary" onClick={(e) => this.handleAddTask()}>Save</Button>
                    <Button disabled={this.state.disableBtn} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }

   async getDataCampus() {
        const url = API_URL.superStudent + '/universities';
        await axios.get(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then(response => response.data)
            .then((data) => {
                this.setState({ dataCampus: data.data })
            })
    }

    setKampusTujuan = (e) => {
        const newData = e.length > 0 ? e[0].code : "";
        const idCampus = e.length > 0 ? e[0].id : "";

        this.setState(prevState => {
            let code_pilihan_kampus = Object.assign({}, prevState.code_pilihan_kampus);
            code_pilihan_kampus = newData;
            return { code_pilihan_kampus };
        })

        this.setState(prevState => {
            let university_id = Object.assign({}, prevState.university_id);
            university_id = idCampus;
            return { university_id };
        }, () => this.getDataProgram());
    }

    setProgramTujuan = (e) => {
        const newData = e.length > 0 ? e[0].id : "";
        this.setState(prevState => {
            let id_program = Object.assign({}, prevState.id_program);
            id_program = newData;
            return { id_program };
        })
    }

    getDataProgram() {
        const url = API_URL.superStudent + '/list-program-university/' + this.state.university_id;
        axios.get(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then(response => response.data)
            .then((data) => {
                this.setState({ dataProgram: data.data })
            })
    }

    loadInternalUser = () => {
        let formData = new FormData()
        formData.append('code_roles', JSON.stringify(['RL003', 'RL006', 'RL013', 'RL023', 'RL026']));
        axios.post(API_URL.superStudent + '/internal-user-active', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(response => response.data.data)
            .then(result => this.setState({ internalUserList: result }))
            .catch(error => console.log(error))
    }
    handleApprove(item) {
        axios.get(API_URL.superStudent + '/student/approval-task/' + item.id + '/edit', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(() => {
                this.loadDataStudents()
            })
    }

    render() {

        const columns = [
            {
                Header: 'Code Student',
                accessor: 'code_students',
                style: { textAlign: 'center' },
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["code_students"] }),
                width: 140,
                filterAll: true,
                Cell: (row) => (
                    <>
                        <span onClick={() => window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/` + row.original.token_students)}>{row.original.code_students}</span>
                    </>
                )
            },
            {
                Header: 'Name',
                accessor: 'nama',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama"] }),
                filterAll: true,
            },
            {
                Header: 'Whatsapp',
                accessor: 'no_hp',
                width: 140,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["no_hp"] }),
                filterAll: true,
                Cell: (row) => (
                    row.original.no_hp.substring(0, 2) == '62' ?
                        <a href={'https://wa.me/' + row.original.no_hp} rel='noreferrer noopener' target='_blank'>{row.original.no_hp}</a>
                        :
                        <a href={'https://wa.me/' + '62' + row.original.no_hp.substring(1)} rel='noreferrer noopener' target='_blank'>{row.original.no_hp}</a>
                )
            },
            {
                Header: 'Consultant',
                accessor: 'consultant.nama',
                width: 120,
                style: { textAlign: 'center' },
                filterable: false,
                Cell: (row) => (
                    <div onClick={e => this.editDetail(row.original.code_students)}>{row.original.consultant.nama}</div>
                ),
            },
            {
                Header: 'Area',
                accessor: 'consultant.area.area',
                width: 120,
                style: { textAlign: 'center' },
                filterable: false
            },
            {
                Header: 'Program',
                accessor: 'program_name',
                width: 250,
                filterable: false
            },
            {
                Header: 'Finish Study',
                accessor: 'date_program_finish',
                width: 150,
                style: { textAlign: 'center' },
                Cell: (row) => (
                    <>{Moment(row.original.date_program_finish).format('D MMM y')}</>
                ),
                filterable: false
            },
            {
                Header: 'Tasks',
                accessor: 'tasks',
                filterable: false,
                width: 400,
                Cell: (row) => (
                    <>
                        <button onClick={() => this.addTodo(row.original.code_students)} className="btn btn-warning btn-xs mr-1"><i className='fa fa-file-text-o'></i></button>
                        <button onClick={() => this.showModalTask(row.original)} className="btn btn-info btn-xs "><i className="fa fa-plus"></i></button>
                        <ul style={{ overflow: 'auto' }} className='row'>
                            {
                                row.original.tasks.length > 0 ?
                                    row.original.tasks.map((item, index) => (
                                        <li style={{ fontSize: '10px' }} className='col mb-1' key={index}> ({Moment(item.created_at).format('DD/MM/YY')}) {item.description} {item.status == 1 ? <span className={'badge bg-success'}>done</span> : <button onClick={() => this.handleApprove(item)} className='btn btn-danger btn-xs'><i className='fa fa-check'></i></button>}</li>
                                    ))
                                    : <></>
                            }
                        </ul>
                    </>
                ),
            },
        ];

        return (
            <>
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-8">
                                    <h5>Finish Study in 6 months</h5>
                                </div>
                                <div className="col-md-4">
                                    <select value={this.state.periode_filter} onChange={this.handlePeriode} className='form-control' name='periode_filter' >
                                            <option value='this_periode'>01 Jan 2025 - 30 Jun 2025 </option>
                                            <option value='next_periode'>01 Jul 2025 - 31 Des 2025 </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <ReactTable
                                        data={this.state.leadsData}
                                        filterable
                                        columns={columns}
                                        collapseOnDataChange={false}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                    />
                                    <Loader showLoading={this.state.showLoading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalUpdate()}
                {this.renderTodoModal()}
                {this.renderModalTask()}
            </>
        );
    }
}


const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser
})

export default connect(reduxState, null)(StudentAlmostDone)
