import React from 'react';
import DataTablesOrganisasi from '../../components/tables/dataTableOrganisasi';


const Home = () => {

    return (
        <DataTablesOrganisasi />

    );
};

export default Home;