/* eslint-disable eqeqeq, no-unused-vars, array-callback-return, jsx-a11y/scope */

import React, { Fragment, Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import ScrollBar from "react-perfect-scrollbar";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import headerIMG from '../../../../assets/images/Img-02.png';
import footerIMG from '../../../../assets/images/Img-01.png';
import ReactTable from 'react-table';
import API_URL from '../../../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import jsPDF from "jspdf";
import Moment from 'moment';
import Swal from 'sweetalert2';
import { Typeahead } from 'react-bootstrap-typeahead';


class SimulasiBiaya extends Component {   
    _isMounted = false; 
    constructor(props) {
        super(props);
        this.state = {
            code_students: '',
            data: [],
            modal: false,
            modalDelete:false,
            modalDetail:false,
            modalDownload:false,
            modalUpdate:false,
            modalEditDetail:false,
            modalFinishUpdate:false,
            title: '',
            titleDelete: '',
            titleDownload: '',
            logName:'',
            editingRow: null,
            deletingRow: null,
            detailRow: {},
            downloadRow: {},
            errors: {},
            operation: '',
            btnDisabled: false,
            dataProfile: [],
            dataSimBiaya: [],
            dataPaySimBiaya: [],
            dataProgram: [],
            toastId: null,
            model: [],
            model2: [],
            pilih_rate: 0,
            total_bayar: 0,
            total_bayar_idr: 0,
            rate: 0,
            display: "none",
            afterAdd: [],
            detail_pembayaran: [],
            total_pay: [],
            grand_total_pay: [],
            mata_uang: "",
            id_pay_sim_biaya: '',
            nama_detail_pembayaran: '',
            nominal: '',
            note_detail_pembayaran: '',
            status_currency: '',
            countrycode: this.props.countrycode != undefined ? this.props.countrycode : '',
            total_rupiah: 0,
            nama_students: "",
            code_university: "",
            nama_university: "",
            nama_program: "",
            id_program: "",
            code_student_program:'',
            notes: "",
            code_sim_biaya: "",
            code_students_document: "",
        }
    }

    componentDidMount() {
        this.getCodeByToken();
    }

    getCodeByToken() {
        const url = API_URL.studentUrl+'token-student/'+this.props.match.params.codeStudent;
        axios.get(url,{
          headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        })
        .then(response => response.data.data)
        .then((data) => {
          this.setState({ 
            code_students: data.code_students
          },() => {
            this.getDetailStudent();
            this.getDataProgram();
          })
        })
    }

    getDataProgram(){
        const url = API_URL.studentUrl+'programs/'+this.state.code_students;
        axios.get(url,{
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        })
        .then(response => response.data.data)
        .then((result) => {
            this.setState({ dataProgram: result })
        })
        .catch(error => console.log(error))
    }

    getDetailStudent() {
        const url = API_URL.studentUrl+'profile-student/'+this.state.code_students;
        axios.get(url,{
            headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
        }).then(response => response.data.data)
        .then((data) => {
            this.setState({ dataProfile: data })
            this.getPaymentSimBiayaAll();
        })
    }

    getSimulasiPembayaran(jenis, code_university) {
        const url = API_URL.superStudent+'/finance/simulasi-biaya-countrycode/'+code_university+'/'+jenis;
        axios.get(url, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data)
        .then((data) => {
            if(data.data.length > 0) {
                this.setState({ dataSimBiaya: data.data, mata_uang: data.data[0].mata_uang })
                this.setAddModel(this.state.dataSimBiaya);
            } else {
                this.setState({ dataSimBiaya: data.data, mata_uang: "Rp" })
                this.setAddModel(this.state.dataSimBiaya);
            }
        })
    }

    getPaymentSimBiayaAll() {
        const url = API_URL.superStudent+'/finance/payment-simbiaya/'+this.state.dataProfile.code_students;
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then(response => response.data)
        .then((data) => {
            this.setState({ 
                dataPaySimBiaya: data.data 
            })
        })
    }

    setAddModel(data) {
        data.map((tipe) => {
            tipe.detail_pembayaran.map((detail) => {
                this.setState(prevState => ({ 
                    model: [
                        ...prevState.model, 
                        { 
                            id_simulasi_biaya: detail.id_simulasi_biaya, 
                            nominal: 0,
                            include: 0,
                            status_currency: 0,
                            note_detail_pembayaran: "" ,
                            rate: 0
                        }
                    ]
                }))
            })
        })
    }

    setNotes = (e) => {
        this.setState({ notes: e.target.value })
    }

    hitungTotal = () => {
        let xtotal_bayar = this.state.total_bayar * this.state.rate;
        this.setState({ 
            total_bayar_idr: xtotal_bayar 
        })
    }

    closeModal() {
        this.setState({
            modal: false,
            modalDelete: false,
            modalDetail: false,
            modalDownload: false,
        });
    }

    closeModalDownload() {
        this.setState({
            modalDownload: false,
        });
    }

    deleteRow(row) {
        this.setState({
            deletingRow: row,
            model: Object.assign({}, row),
            titleDelete: 'Delete Simulasi Biaya',
            modalDelete: true,
            operation: 'delete',
            logName: 'DELETE-SIM-BIAYA'
        });
    }

    handleCheckboxChange = (e, i) => {
        let checked = e.target.checked;
        let _model = [...this.state.model];

        if (checked) {
            _model[i].include = 1;
            _model.push();

            this.setState(prevState => ({
                total_bayar: prevState.total_bayar + parseInt(_model[i].nominal)
            }))
        } else {
            _model[i].include = 0;
            _model.push();

            this.setState(prevState => ({
                total_bayar: prevState.total_bayar - parseInt(_model[i].nominal)
            }))
        }
    };

    handleCheckboxUpdate = (e) => {
        let checked = e.target.checked;

        if (checked) {
            this.setState({
                include: e.target.value
            })
        } else {
            this.setState({
                include: 0
            })
        }
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    handelChangeProgram = (e) => {
        if (e.length > 0) {
            this.setState({
                code_university: e[0].university.code_school_rep,
                nama_university: e[0].university.nama_sekolah,
                countrycode: e[0].university.countrycode,
                id_program: e[0].pivot.id_program,
                nama_program: e[0].program_name,
                code_student_program: e[0].pivot.code_student_program
            },() => {
                this.getSimulasiPembayaran('S', this.state.countrycode)
            })
        } else {
            this.setState({
                code_university: "",
                nama_university: "",
                countrycode: "",
                id_program: "",
                nama_program: "",
                code_student_program:'',
                model: [],
            })
        }
    }

    addSimBiaya() {
        this.setState({
            title: 'Simulasi Biaya',
            operation: 'add',
            modal: true,
            logName: 'ADD-SIM-BIAYA',
            model: [],
        })
    }

    setNominal = (e, x, status) => {
        let _model = [...this.state.model];
        _model[x].nominal = e.target.value;
        _model[x].status_currency = status;
        _model.push();
    }

    setRateSingle = (e, x) => {
        let _model = [...this.state.model];
        _model[x].rate = e.target.value;
        _model.push();
    }

    setNotePembayaran = (e, x) => {
        let _model = [...this.state.model];
        _model[x].note_detail_pembayaran = e.target.value;
        _model.push();
    }

    setRate = (e) => {
        this.setState({ 
            rate: e.target.value 
        })
    }

    sendData() {
        this.setState({
            btnDisabled: true
        });

        let jenis 

        if (this.state.dataProfile.code_status == 'ST014') {
            jenis = 'T';
        } else if (this.state.dataProfile.code_status == 'ST015') {
            jenis = 'J';
        } else {
            jenis = 'S';
        }

        var formData = new FormData();
        formData.append('model', JSON.stringify(this.state.model));
        formData.append('rate', this.state.rate);
        formData.append('total_bayar', this.state.total_bayar);
        formData.append('code_students', this.state.code_students);
        formData.append('countrycode', this.state.countrycode);
        formData.append('jenis', jenis);
        formData.append('code_school_rep', this.state.code_university); 
        formData.append('nama_university', this.state.nama_university); 
        formData.append('id_program', this.state.id_program); 
        formData.append('code_student_program', this.state.code_student_program)
        formData.append('nama_program', this.state.nama_program);
        formData.append('notes', this.state.notes);

        axios.post(API_URL.superStudent+'/finance/payment-simbiaya', formData, { 
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then((reply)=> {
            if(reply.status === 200){
                if (this.state.operation == "add") {
                    toast.success("Save Sucsessfully");
                } else {
                    toast.success("Update Sucsessfully");
                } 
                this.getPaymentSimBiayaAll();
                this.closeModal();
                window.location.reload();
            }
        }).catch(function(reply){
            console.log(reply)
        });
    }

    saveDeleteRow() {
        this.setState({
            btnDisabled: true
        });

        let row = Object.assign({}, this.state.deletingRow, this.state.model);
        let data = this.state.data.slice();
        let index = data.indexOf(this.state.deletingRow);
        data.splice(index, 1, row);

        var formData = new FormData();
        formData.append('code_students',data[0].code_students);
        formData.append('code_sim_biaya',data[0].code_sim_biaya);

        axios.post(API_URL.superStudent+'/finance/payment-simbiaya/delete', formData, { 
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then((reply)=>{
            if(reply.data.code === 200){
                toast.success("Delete Sucsessfully");
                this.getPaymentSimBiayaAll();
                this.closeModal();
                window.location.reload()
            }
        }).catch(function(reply){
            console.log(reply)
        });
    }

    saveEditDetail() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('nominal', this.state.nominal);
        formData.append('note_detail_pembayaran', this.state.note_detail_pembayaran);
        formData.append('include', this.state.include);
        formData.append('_method', 'PATCH');

        axios.post(API_URL.superStudent+'/finance/payment-simbiaya/'+this.state.id_pay_sim_biaya, formData, { 
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then((reply)=>{
            if (reply.data.code == 200) {
                toast.success("Save Sucsessfully");
                this.setState({
                    modalEditDetail: false,
                    modalUpdate: false
                },() => {
                    this.updateRow(reply.data.data, 'second');
                })
            }
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    updateRow(row, status) {
        if (status == 'first') {
            this.setState({
                code_sim_biaya: row.code_sim_biaya,
                code_students: row.code_students,
                countrycode: row.countrycode,
                jenis: row.jenis,
                code_students_document: row.code_students_document,
                nama_students: row.nama,
                nama_university: row.nama_sekolah,
                nama_program: row.program_name,
                notes: row.notes,
                code_university: row.code_university,
                id_program: row.id_program,
            }, () => {
                var formData = new FormData();
                formData.append('code_sim_biaya', this.state.code_sim_biaya);
                formData.append('code_students', this.state.code_students);
                formData.append('countrycode', this.state.countrycode);

                axios.post(API_URL.superStudent+'/finance/payment-simbiaya/paymentbycodeupdate', formData, { 
                    headers: { 
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${this.props.dataToken}`
                    }
                }).then((reply)=>{
                    if(reply.data.code === 200){
                        this.setState({ 
                            afterAdd: reply.data.data,
                            detail_pembayaran: reply.data.detail_pembayaran,
                            total_pay: reply.data.grand_total,
                            grand_total_pay: reply.data.sub_total,
                            titleDelete: 'Edit Simulasi Biaya',
                            logName: 'EDIT-SIM-BIAYA'
                        },() => {
                            this.setState({
                                modalUpdate: true
                            })
                        })
                    }
                }).catch(function(reply){
                    console.log(reply.data)
                });
            })
        } else {
            var formData = new FormData();
            formData.append('code_sim_biaya', this.state.code_sim_biaya);
            formData.append('code_students', this.state.code_students);
            formData.append('countrycode', this.state.countrycode);

            axios.post(API_URL.superStudent+'/finance/payment-simbiaya/paymentbycodeupdate', formData, { 
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${this.props.dataToken}`
                }
            }).then((reply)=>{
                if(reply.data.code === 200){
                    this.setState({ 
                        afterAdd: reply.data.data,
                        detail_pembayaran: reply.data.detail_pembayaran,
                        total_pay: reply.data.grand_total,
                        grand_total_pay: reply.data.sub_total,
                        titleDelete: 'Edit Simulasi Biaya',
                        logName: 'EDIT-SIM-BIAYA'
                    },() => {
                        this.setState({
                            modalUpdate: true
                        })
                    })
                }
            }).catch(function(reply){
                console.log(reply.data)
            });
        }
    }
 
    downloadRow(payment,countrycode) {
        let jenis 

        if (this.state.dataProfile.code_status == 'ST014') {
            jenis = 'T';
        } else if (this.state.dataProfile.code_status == 'ST015') {
            jenis = 'J';
        } else {
            jenis = 'S';
        }
        var formData = new FormData();
        formData.append('code_students', payment.original.code_students);
        formData.append('code_sim_biaya', payment.original.code_sim_biaya);
        formData.append('countrycode', countrycode);
        formData.append('jenis', jenis);

        axios.post(API_URL.master+'/paymentsimbiaya/paymentbycode',formData, { headers: { 'Authorization': `Bearer ${API_URL.masterKey}` }
        }).then((reply)=>{
            if(reply.data.code === 200){
                // console.log(reply.data.data);
                this.setState({ 
                    afterAdd: reply.data.data,
                    total_pay: reply.data.grand_total,
                    grand_total_pay: reply.data.sub_total,
                    total_rupiah: reply.data.sub_total_rupiah,
                    titleDownload: 'Download Simulasi Biaya',
                    modal: false,
                    modalDownload: true,
                    logName: 'DELETE-SIM-BIAYA'
                })
            }
        }).catch(function(reply){
            console.log(reply.data)
        });
    }

    actionDownload(nama) {
        var doc = new jsPDF('p', 'pt', 'a4');
        doc.html(document.querySelector('#htmlPDF'), {
            callback: function (pdf) {
                pdf.save('SimulasiBiaya_'+nama.replace(/\s/g, '')+'.pdf');
            }
        });
    }

    closeDialogDelete() {
        this.setState({
            modalDelete: false,
            modalUpdate: false,
            modalFinishUpdate: false,
        });
    }

    finishUpdate() {
        this.setState({
            btnDisabled: false,
            modalFinishUpdate: true
        });
    }

    saveFinishUpdate() {
        this.setState({
            btnDisabled: false
        })

        const { 
            code_sim_biaya, code_students, countrycode, jenis,
            nama_students, nama_university, nama_program,
            notes, code_students_document, code_university, 
            id_program
        } = this.state

        var formData = new FormData(); 
        formData.append('code_sim_biaya', code_sim_biaya); 
        formData.append('code_students', code_students);
        formData.append('countrycode', countrycode);
        formData.append('jenis', jenis);
        formData.append('nama_students', nama_students);
        formData.append('nama_university', nama_university);
        formData.append('nama_program', nama_program);
        formData.append('notes', notes); 
        formData.append('code_students_document', code_students_document); 
        formData.append('code_school_rep', code_university); 
        formData.append('id_program', id_program); 

        axios.post(API_URL.superStudent+'/finance/payment-simbiaya/paymentbycodereupload', formData, { 
            headers: { 
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then((reply)=>{
            toast.success("Save Sucsessfully");
            window.location.reload();
        }).catch(function(reply){
            console.log(reply.data)
        });
    }

    editDetailPayment(row) {
        this.setState({ 
            btnDisabled: false,
            modalEditDetail: true,
            id_pay_sim_biaya: row.id_pay_sim_biaya,
            nama_detail_pembayaran: row.nama_detail_pembayaran,
            nominal: row.nominal,
            note_detail_pembayaran: row.note_detail_pembayaran,
            status_currency: row.status_currency,
            mata_uang: row.code_mata_uang,
            include: row.include,
        })
    }

    renderEditDetail() {
        const modal3 = this.state.modalEditDetail;
        return (
            <Modal className="modal-dialog" isOpen={modal3} toggle={() => this.setState({ modalEditDetail: false })} size="lg">
                <ModalHeader toggle={() => this.setState({ modalEditDetail: false })}>Edit Detail Simulasi Biaya</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                    <div className="form-group row">
                        <div className="col-sm-5 checkbox checkbox-dark" style={{textAlign: 'end'}}>
                            <input className="col-sm-1" id={this.state.id_pay_sim_biaya} onChange={(e) => this.handleCheckboxUpdate(e)} type="checkbox" value="1" defaultChecked={this.state.include == 1 ? "true" : ""} />
                            <label className="col-sm-10 col-form-label" for={this.state.id_pay_sim_biaya} style={{textAlign: 'center'}}>{this.state.nama_detail_pembayaran} ({this.state.status_currency == 0 ? this.state.mata_uang : 'IDR'})</label>
                        </div>
                        <div className="col-sm-3">
                            <input className="form-control" name="nominal" type="double" id="nominal" value={this.state.nominal} placeholder='Nominal Biaya'
                                onChange={(e) => this.handleInput(e)}
                            />
                        </div>
                        <div className="col-sm-3">
                            <input className="form-control" name="note_detail_pembayaran" type="text" id="note_detail_pembayaran" value={this.state.note_detail_pembayaran} placeholder='Noted Detail Biaya'
                                onChange={(e) => this.handleInput(e)}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={()=>this.saveEditDetail()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.setState({ modalEditDetail: false })}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderEditDialog() {
        const data = this.props.data;
        const modal = this.state.modal;
        const { title } = this.state;
        var x = -1;
        let pilih_rate = this.state.pilih_rate;
        return (
            <Modal className="modal-dialog" isOpen={modal} toggle={() => this.closeModal()} size="lg">
                <ModalHeader toggle={() => this.closeModal()}>{title} - {data.countrycode}</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                    <form className="theme-form">
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Program</label>
                            <div className="col-sm-9">
                                <Typeahead
                                    id="basic-typeahead-multiple"
                                    labelKey="program_name"
                                    options={this.state.dataProgram}
                                    onChange={this.handelChangeProgram}
                                    placeholder="can choose several data."
                                />
                            </div>
                        </div>
                        {this.state.dataSimBiaya.map((tipe, i) => {
                            return (
                                <div>
                                    <div className="form-group row">
                                        <div className="col-sm-3">
                                            <label for={tipe.id_tipe_pembayaran}>{tipe.tipe_pembayaran}</label>
                                        </div>
                                    </div>
                                    {tipe.detail_pembayaran.map((detail, i) => {
                                        return  <div className="form-group row">
                                                    <div className="col-sm-5 checkbox checkbox-dark" style={{textAlign: 'end'}}>
                                                        <input className="col-sm-1" id={detail.id_simulasi_biaya} onChange={(e) => this.handleCheckboxChange(e, detail.index)} type="checkbox" value="1"/>
                                                        <label className="col-sm-10 col-form-label" for={detail.id_simulasi_biaya} style={{textAlign: 'center'}}>{detail.nama_detail_pembayaran} ({detail.status_currency == 0 ? this.state.mata_uang : detail.status_currency == 1 ? 'IDR' : detail.status_currency == null ? <i>deleted</i> : detail.status_currency})</label>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <input className="form-control" name="nominal" type="number" id="nominal" value={detail.nominal} placeholder='Nominal Biaya'
                                                            onChange={(e) => this.setNominal(e, detail.index, detail.status_currency)}
                                                        />
                                                        {
                                                            detail.status_currency !== '0' && detail.status_currency !== '1' ?
                                                            <input className="form-control" name="rate" type="number" id="rate" value={detail.rate} placeholder='Rate'
                                                                onChange={(e) => this.setRateSingle(e, detail.index)}
                                                            />
                                                            :''
                                                        }
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <input className="form-control" name="note_detail_pembayaran" type="text" id="note_detail_pembayaran" value={detail.note_detail_pembayaran} placeholder='Noted Detail Biaya'
                                                            onChange={(e) => this.setNotePembayaran(e, detail.index)}
                                                        />
                                                    </div>
                                                </div>
                                    })}
                                </div>
                            ) 
                        })}
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Notes</label>
                            <div className="col-sm-5">
                                <input onChange={(e) => this.setNotes(e)} className="form-control" name="notes" type="text" id="notes" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Rate</label>
                            <div className="col-sm-5">
                                <input onChange={(e) => this.setRate(e)} className="form-control" name="rate" type="text" id="rate" />
                            </div>
                            <div className='col-sm-3'>
                                <button onClick={() => this.hitungTotal()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-calculator" style={{ width: 35, fontSize: 16, padding: 11}}></i></button>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Total IDR</label>
                            <div className="col-sm-7">
                                <label>Rp {this.state.total_bayar_idr}</label>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">Total {this.state.mata_uang}</label>
                            <div className="col-sm-7">
                                <label>{this.state.mata_uang} {this.state.total_bayar}</label>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={()=>this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderUpdateDialog() {
        const { titleDelete } = this.state;
        const modal2 = this.state.modalUpdate;
        return (
            <Modal isOpen={modal2} toggle={() => this.closeDialogDelete()}>
                <ModalHeader toggle={() => this.closeDialogDelete()}>{titleDelete}</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                    <form className="theme-form">
                        {
                            this.state.detail_pembayaran.length > 0 ?
                            this.state.detail_pembayaran.map((detail) => (
                                <div className="form-group row">
                                    <div className="col-sm-6">
                                        <label className="col-form-label" style={{textAlign: 'center'}}>
                                            {detail.nama_detail_pembayaran} ({detail.status_currency == 0 ? detail.code_mata_uang : detail.status_currency == 1 ? 'IDR' : detail.status_currency == null ? <i>deleted</i> : detail.status_currency})
                                        </label>
                                    </div>
                                    <div className="col-sm-6">
                                        <input className="form-control" name="nominal" type="double" id="nominal" value={detail.nominal}
                                            onClick={() => this.editDetailPayment(detail)}
                                        />
                                    </div>
                                </div>
                            ))
                            : 
                            <div>
                            </div>
                        }

                        {
                            this.state.grand_total_pay.length > 0 ?
                            <div>
                                <div className="card-header" style={{marginBottom: "-40px"}}>
                                    <div className="col-sm-12" style={{fontSize: 'smaller'}}>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={'2'} style={{width: '190px'}}>Grand Total </td>
                                                        <td>{this.state.mata_uang} {this.state.grand_total_pay[0].total_nominal}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={'2'} style={{width: '190px'}}>Rate </td>
                                                        <td>Rp {this.state.grand_total_pay[0].rate}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={'2'} style={{width: '190px'}}>Rate Date</td>
                                                        <td>{Moment(this.state.grand_total_pay[0].rate_date).format('DD-MMMM-YYYY')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={'2'} style={{width: '190px'}}>Total Payment</td>
                                                        <td>{this.state.grand_total_pay[0].rate == '0' ? this.state.mata_uang : 'Rp'} {this.state.grand_total_pay[0].total_payment}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={'2'} style={{width: '190px'}}>Total (IDR Only)</td>
                                                        <td>Rp {this.state.total_rupiah == 0 ? 0 :this.state.total_rupiah }</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : 
                            <div>
                            </div>
                        }
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => this.finishUpdate()}>Finish</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderDeleteDialog() {
        const row = this.state.data;
        const { titleDelete } = this.state;
        const modal2 = this.state.modalDelete;
        return (
            <Modal isOpen={modal2} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{titleDelete}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveDeleteRow()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialogDelete()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderDownloadDialog() {
        const { titleDownload } = this.state;
        const modal = this.state.modalDownload;
        return (
            <Modal className="modal-dialog" isOpen={modal} toggle={() => this.closeModalDownload()} size="lg">
                <ModalHeader toggle={() => this.closeModalDownload()}>{titleDownload}</ModalHeader>
                <ModalBody>
                    <div id="htmlPDF">
                        <header>
                            <img src={headerIMG} alt="" style={{width: '600px'}}/>
                        </header>
                        <div className="card">
                            <div className="card-header" style={{marginBottom: "-40px"}}>
                                <h6>Kepada</h6><span><h6>{this.state.dataProfile.nama}</h6></span>
                            </div>
                            {
                                this.state.detail_pembayaran.length > 0 ? 
                                <div>
                                    <div className="card-header" style={{marginBottom: "-40px"}}>
                                        <div className="col-sm-9" style={{fontSize: 'smaller'}}>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead className="table-danger" style={{background: '#be1d2c'}}>
                                                        <tr>
                                                            <th scope="col" style={{color: 'white'}}>NO.</th>
                                                            <th scope="col" style={{color: 'white'}}>Detail Pembayaran</th>
                                                            <th scope="col" style={{color: 'white'}}>Harga</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.detail_pembayaran.map((detail, i) => {
                                                            return  <tr>
                                                                        <td scope="row">{i+1}</td>
                                                                        <td style={{width: '65%'}}>{detail.nama_detail_pembayaran}<br/><span>{detail.note_detail_pembayaran}</span><span>({detail.status_refund == 0 ? "Non Refundable" : "Refundable"})</span></td>
                                                                        <td style={{width: '65%'}}>{detail.status_currency == 0 ? detail.code_mata_uang : 'Rp'} {detail.nominal}</td>
                                                                    </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div></div>
                            }
                            <br/>
                            {this.state.grand_total_pay.length > 0 ?
                            <div>
                                <div className="card-header" style={{marginBottom: "-40px"}}>
                                    <div className="col-sm-9" style={{fontSize: 'smaller'}}>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={'2'} style={{width: '190px'}}>Grand Total </td>
                                                        <td>{this.state.grand_total_pay[0].code_mata_uang} {this.state.grand_total_pay[0].total_nominal}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={'2'} style={{width: '190px'}}>Rate </td>
                                                        <td>Rp {this.state.grand_total_pay[0].rate}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={'2'} style={{width: '190px'}}>Rate Date</td>
                                                        <td>{this.state.grand_total_pay[0].rate_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={'2'} style={{width: '190px'}}>Total Payment</td>
                                                        <td>{this.state.grand_total_pay[0].rate == '0' ? this.state.mata_uang : 'Rp'} {this.state.grand_total_pay[0].total_payment}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={'2'} style={{width: '190px'}}>Total (IDR Only)</td>
                                                        <td>Rp {this.state.total_rupiah}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : 
                            <div>
                            </div>}
                        </div>
                        <header>
                            <img src={footerIMG} alt="" style={{width: '600px'}}/>
                        </header>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    renderFinishUpdateDialog() {
        const modal2 = this.state.modalFinishUpdate;
        return (
            <Modal isOpen={modal2} toggle={() => this.setState({ modalFinishUpdate: false })}>
                <ModalHeader toggle={() => this.setState({ modalFinishUpdate: false })}>Finish Update</ModalHeader>
                <ModalBody>
                    Finish Update Data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveFinishUpdate()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialogDelete()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        const { pageSize, multiSelectOption, myData } = this.state.dataSimBiaya;
        const columns = [
            {
                Header: 'Code Simulasi Biaya',
                accessor: 'code_sim_biaya',
                style: {textAlign: 'center'},
            },
            {
                Header: 'University',
                accessor: 'nama_sekolah',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Program',
                accessor: 'program_name',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Total Simulasi (Non IDR)',
                accessor: 'total_nominal',
                style: {textAlign: 'center'},
                Cell: (row) => (
                    <span>{row.original.total_nominal} ({row.original.code_mata_uang})</span>
                )
            },
            {
                Header: 'Total Simulasi (IDR)',
                accessor: 'total_payment',
                style: {textAlign: 'center'},
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div className='row justify-content-center'>
                        <div className='col-md-3'>
                            <button onClick={() => this.updateRow(row.original, "first")} className="btn btn-primary btn-xs" type="button" style={{border:'none',display:this.props.display}}><i className="fa fa-pencil"></i></button>
                        </div>
                        <div className='col-md-3'>
                            <button onClick={() => this.deleteRow(row.original)} className="btn btn-danger btn-xs" type="button" style={{border:'none',display:this.props.display}}><i className="fa fa-trash"></i></button>
                        </div>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return (
            <Fragment>
                <div>
                    <div className="card">
                        <div className="card-header bg-red-color">
                            <div className="row">
                                <div className="col-10">
                                    <h5 style={{fontSize:15}}>Simulasi Biaya</h5>
                                </div>
                                <div className="col-2">
                                    <button onClick={() => this.addSimBiaya()} className="btn btn-light btn-xs" type="button" style={{marginLeft: 'auto', border:'none',display:this.props.display}} ><i className="fa fa-plus color-red"></i></button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <ReactTable 
                                        data={this.state.dataPaySimBiaya}
                                        columns={columns}
                                        pageSize={pageSize}
                                        showPagination={false}
                                        className="-striped -highlight"
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                        style={{
                                            height: "400px"
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderEditDialog()}
                {this.renderDeleteDialog()}
                {this.renderUpdateDialog()}
                {this.renderDownloadDialog()}
                {this.renderEditDetail()}
                {this.renderFinishUpdateDialog()}
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    dataToken: state.Login.lgnInitialize,
})
  
export default connect(reduxState, null)(withRouter(SimulasiBiaya));