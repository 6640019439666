import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import API_URL from '../../../ApiUrl';
// import 'bootstrap/dist/css/bootstrap.min.css';

class JobVacancy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listKandidat: [],
      currentPage: 1,
      itemsPerPage: 10,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadDataKandidat();
  }

  loadDataKandidat = async () => {
    this.setState({ isLoading: true });

    try {

      // Lakukan request ke API
      const response = await axios.post(
        `${API_URL.superStudent}/niec-career-candidate/list-candidate`,
        {}, // body kosong
        {
          headers: {
            Authorization: `Bearer ${this.props.dataToken}`,
          },
        }
      );

      // Flatten data kandidat jika ada
      const allKandidat = response.data || [];

      // Sort data berdasarkan created_at secara descending
      const sortedKandidat = allKandidat.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      // Perbarui state dengan data kandidat yang sudah diurutkan
      this.setState({ listKandidat: sortedKandidat });
    } catch (error) {
      console.error("Error fetching candidate data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  render() {
    const { listKandidat, currentPage, itemsPerPage, isLoading } = this.state;
    const totalPages = Math.ceil(listKandidat.length / itemsPerPage);
    const currentData = listKandidat.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    return (
      <div className="p-3">
        <div className="row">
          <div className="col-md-12">
            <div className="card shadow-sm">
              <div className="card-header bg-warning text-white">
                <h6 className="mb-0 text-center text-black">NEW CANDIDATES AWAITING REVIEW</h6>
              </div>
              <div className="card-body" style={{ fontSize: '0.8rem' }}>
                {isLoading ? (
                  <div className="text-center p-3">Loading...</div>
                ) : listKandidat.length > 0 ? (
                  <>
                    <table className="table table-hover table-sm">
                      <thead className="thead-light">
                        <tr>
                          <th className="small text-center">Nama Lengkap</th>
                          <th className="small text-center">Date Apply</th>
                          <th className="small text-center">Melamar Untuk Posisi</th>
                          <th className="small text-center">Email</th>
                          <th className="small text-center">No HP</th>
                          {/* <th className="small text-center">CV</th> */}
                          <th className="small text-center">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.map((kandidat, index) => (
                          <tr key={index}>
                            <td className="small py-1">{kandidat.nama_lengkap}</td>
                            <td className="small py-1">
                              {new Date(kandidat.created_at).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })}
                            </td>
                            <td className="small py-1">{kandidat.title || 'Posisi tidak ditemukan'}</td>
                            <td className="small py-1">{kandidat.email}</td>
                            <td className="small py-1">{kandidat.no_hp}</td>
                            {/* <td className="small py-1">
                              <a
                                href={kandidat.file_cv}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-link btn-sm"
                              >
                                Lihat CV
                              </a>
                            </td> */}
                            <td className="small py-1">
                              <p className="text-danger">{kandidat.status}</p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <nav>
                      <ul className="pagination justify-content-center">
                        {Array.from({ length: totalPages }, (_, index) => (
                          <li
                            key={index}
                            className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                          >
                            <button
                              className="page-link btn-sm"
                              onClick={() => this.handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </>
                ) : (
                  <div className="text-center p-3">
                    Tidak ada kandidat yang ditemukan.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dataToken: state.Login.lgnInitialize,
});

export default connect(mapStateToProps)(JobVacancy);