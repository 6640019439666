import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Bar } from 'react-chartjs-2';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import API_URL from '../../../ApiUrl';

class TotalMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeType: 2,
      targetYear: false,
      rekapMemberKonsultan: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadGraphicTotalMember();
  }

  loadGraphicTotalMember = () => {
    const { dataToken } = this.props;

    this.setState({ isLoading: true });
    const formData = new FormData();

    axios
      .post(`${API_URL.superStudent}/consultant-peformance/all-members`, formData, {
        headers: { Authorization: `Bearer ${dataToken}` },
      })
      .then((response) => {
        this.setState({ rekapMemberKonsultan: response.data.data });
      })
      .catch((error) => {
        console.error('Error fetching rekap konsultan:', error.response || error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const dataArr = this.state.rekapMemberKonsultan;

    const konsultan = dataArr.map(data => {
      return data.nama;
  });
  
    const totalmember = dataArr.map(data => {
      return data.members_count;
  });

  const dataGrafik = {
      labels: konsultan,
      datasets: [
          {
              label: 'Total Member',
              data: totalmember,
              borderColor: 'blue',
              backgroundColor: 'blue',
              borderWidth: 1,
          },
      ],
  };

    return (
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <h5>Total Member Konsultan</h5>
          </div>
          <div className="card-body">
            {this.state.isLoading ? (
              <div className="text-center p-4">Loading...</div>
            ) : 
            (
              <Bar data={dataGrafik} options={{ maintainAspectRatio: true }} />
              // <ResponsiveContainer width="100%" height={300}>
              //   <BarChart data={rekapMemberKonsultan}>
              //     <CartesianGrid strokeDasharray="3 3" />
              //     <XAxis dataKey="name" angle={-45} textAnchor="end" height={70} />
              //     <YAxis />
              //     <Tooltip />
              //     <Legend />
              //     <Bar dataKey="member" fill="url(#gradient)" />
              //     <defs>
              //       <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
              //         <stop offset="0%" stopColor="#001f3f" stopOpacity={1} />
              //         <stop offset="100%" stopColor="#0074D9" stopOpacity={1} />
              //       </linearGradient>
              //     </defs>
              //   </BarChart>
              // </ResponsiveContainer>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dataToken: state.Login.lgnInitialize,
});

export default connect(mapStateToProps)(TotalMember);