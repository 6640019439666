import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Home, User, List, FileText, Inbox } from 'react-feather';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';

const Consultant = () => {
    return (
        <Fragment>
            <Breadcrumb title="Employee" parent="Dashboard" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <RoutedTabs>
                            <div className="tabs tab-title">
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/consultant/home`}>
                                    <Home /> Home
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/consultant/data`}>
                                    <User /> Employee
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/consultant/cuti`}>
                                    <List /> Cuti & Day Payment
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/consultant/conseling`}>
                                    <FileText /> Jadwal Conseling
                                </NavTab>
                                <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/consultant/appraisal`}>
                                    <Inbox /> Appraisal Employee
                                </NavTab>
                            </div>
                        </RoutedTabs>

                        <Switch>
                            <Route
                                exact
                                path={`${process.env.PUBLIC_URL}/pages/consultant`}
                                render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/consultant/home`} />}
                            />
                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant/home`}/>
                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant/data`}/>
                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant/cuti`}/>
                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant/conseling`}/>
                            <Route path={`${process.env.PUBLIC_URL}/pages/consultant/appraisal`}/>
                        </Switch>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
  })
  
  export default connect(reduxState, null)(withRouter(Consultant));