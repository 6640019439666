/* eslint-disable eqeqeq */

import React, { Component } from 'react'
import { matchSorter } from 'match-sorter'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { withRouter } from "react-router"
import Moment from 'moment'
import axios from 'axios'
import CKEditors from "react-ckeditor-component";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { toast } from 'react-toastify';
import RequiredTag from '../../../components/dashboard/partition/component/required-tag'
import setRangeDate from '../../../helper/general-function'
import API_URL from '../../../ApiUrl'
import Loader from '../../../components/common/loader'

class EnglishGroupClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            filtered: [],
            filterStatus: '',
            textDate: '',
            input_date: '',
            form_date: '',
            code_register_group: '',
            id_note: '',
            textSave: 'Save',
            loading: false,
            modalDate: false,
            modalNote: false,
            modalDelete: false,
            btnDisabled: false,
            date_time: '',
            skill_fokus: [],
            list_students: [],
            selected_students: [],
            code_teacher: '',
            teaching_detail: '',
            assignment: '',
            dataType: '',
            list_skill: [
                { code: 'L', name: 'Listening' },
                { code: 'R', name: 'Reading' },
                { code: 'W', name: 'Writing' },
                { code: 'S', name: 'Speaking' },
                { code: 'V', name: 'Vocabulary' },
                { code: 'G', name: 'Grammer' },
                { code: 'P', name: 'Pronuncation' },
            ]
        }
    }

    componentDidMount() {
        this.loadDataGroups()
    }

    handleChangeDate = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.setDate()
        })
    }

    handleInput = (e) => {
        console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setHandleSkill = (e) => {
        this.setState({
            skill_fokus: e
        })
    }

    setDate() {
        let data = setRangeDate(this.state.date)
        this.setState({
            start_date: data.start,
            end_date: data.end
        }, () => {
            this.loadDataGroups()
        })
    }

    loadDataGroups = () => {
        let formData = new FormData()
        this.setState({
            loading: true
        })
        formData.append('id_teacher', this.props.userableData.userable.id_teacher)
        axios.post(API_URL.superStudent + '/english-division/list-group', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then((response) => {
                this.setState({
                    groups: response.data.data,
                    loading: false
                })
            })
    }

    changeTeachingDetail(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            teaching_detail: newContent
        })
    }

    changeAssignment(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            teaching_detail: newContent
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;

                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    }

    SetFilterStatus = (e) => {
        const newData = e.target.value;
        this.setState(prevState => {
            let filterStatus = newData;
            return { filterStatus };
        }, () => {
            this.onFilteredChangeCustom(newData, "status");
        })
    }

    closeDialog = () => {
        this.setState({
            modalDate: false,
            modalNote: false
        })
    }

    showDateStartDialog = (row) => {
        this.setState({
            modalDate: true,
            textDate: 'Start Date',
            code_register_group: row.code_register_grup,
            form_date: 'start_date',
            input_date: Moment().format('YYYY-MM-DD')
        })
    }

    showDateFinishDialog = (row) => {
        this.setState({
            modalDate: true,
            textDate: 'Finish Date',
            code_register_group: row.code_register_grup,
            form_date: 'end_date',
            input_date: Moment().format('YYYY-MM-DD')
        })
    }

    showModalNoted = (row) => {
        this.setState({
            modalNote: true,
            dataType: 'add',
            date_time: Moment().format("YYYY-MM-DDTHH:mm:ss"),
            skill_focus: [],
            list_students: row,
            teaching_detail: '',
            assignment: '',
            code_register_group: row[0].grup_code,
            selected_students: [],
            code_teacher: this.props.userableData.userable.code_teacher
        })
    }


    toggleStudents = (item) => {
        this.setState((prevState) => {
            const { selected_students } = prevState;

            if (selected_students.includes(item)) {
                return {
                    selected_students: selected_students.filter((i) => i !== item),
                };
            } else {
                return {
                    selected_students: [...selected_students, item],
                };
            }
        });
    };

    handleDelete = (e) => {
        this.setState({
            id_note: e.id,
            modalDelete: true
        })
    }

    handleEdit = (data) => {
        console.log(data)
        this.setState({
            modalNote: true,
            id_note:data.id,
            dataType: 'edit',
            date_time: Moment(data.date_time).format("YYYY-MM-DDTHH:mm"),
            skill_fokus: data.skill_focus,
            teaching_detail: data.teaching_detail,
            assignment: data.assignment,
        })
    }

    saveDelete = () => {
        axios.delete(API_URL.superStudent + '/english-division/note/' + this.state.id_note, {
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
            .then(() => {
                this.setState({ modalDelete: false })
                this.loadDataGroups()
                toast.success('data deleted successfully')
            })
            .catch(() => {
                toast.error('there is an error')
            })
    }

    saveData = () => {
        this.setState({
            textSave: 'Saving...',
            btnDisabled: true,
        })
        let formData = new FormData()
        formData.append('code_register_grup', this.state.code_register_group)
        formData.append('column', this.state.form_date);
        formData.append('value', this.state.input_date)
        axios.post(API_URL.superStudent + '/english-division/update-date', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then(() => {
                this.setState({
                    textSave: 'Save',
                    btnDisabled: false,
                    modalDate: false,
                })
                toast.success('data successfully updated')
                this.loadDataGroups()
            })
            .catch(() => {
                this.setState({
                    textSave: 'Save',
                    modalDate: false,
                    btnDisabled: false,
                })
                toast.error('data failed to update');
            })
    }

    renderDateDialog() {
        const { modalDate } = this.state;
        return (
            <Modal isOpen={modalDate} toggle={() => this.setState({ modalDate: false })}>
                <ModalHeader toggle={() => this.setState({ modalDate: false })}>Update Data</ModalHeader>
                <ModalBody>
                    <form>
                        <div className='form-grup'>
                            <label className='control-label'>{this.state.textDate}</label>
                            <input type='date' className='form-control' onChange={this.handleInput} name='input_date' value={this.state.input_date} />
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveData()}>{this.state.textSave}</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    saveUpdate = () => {
        this.setState({
            btnDisabled: true,
            textSave: 'Saving...'
        })
        let formData = new FormData()
        formData.append('id', this.state.id_note)
        formData.append('date_time', this.state.date_time)
        formData.append('code_group', this.state.code_register_group)
        formData.append('code_teacher', this.props.userableData.userable.code_teacher)
        formData.append('skill_focus', JSON.stringify(this.state.skill_fokus))
        formData.append('students', JSON.stringify(this.state.selected_students))
        formData.append('teaching_detail', this.state.teaching_detail)
        formData.append('assignment', this.state.assignment)


        let url = '/english-division/note'
        let method = 'post'

        if (this.state.dataType === 'edit'){
            formData.append('_method','PATCH');
            url = '/english-division/note/'+this.state.id_note
        }

        axios({
            method,
            url: API_URL.superStudent+url,
            data: formData,
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`,
            }
        })
            .then(() => {
                this.loadDataGroups()
                this.setState({
                    btnDisabled: false,
                    modalNote: false,
                    textSave: 'Save'
                })
                toast.success('Updated data successfully')
            })
            .catch(() => {
                this.setState({
                    btnDisabled: false,
                    modalNote: false,
                    textSave: 'Save'
                })
                toast.error('there is an error')
            })

    }

    renderNotedDialog() {
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modalNote} toggle={() => this.setState({ modalNote: false })}>
                    <ModalHeader toggle={() => this.setState({ modalNote: false })}>Add Data</ModalHeader>
                    <ModalBody>
                        <form >
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='form-group'>
                                        <label className='control-label'>Date & Time <RequiredTag /></label>
                                        <input className='form-control' type='datetime-local' name='date_time' value={this.state.date_time} onChange={this.handleInput} />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Skill Focus <RequiredTag /></label>
                                        <Typeahead
                                            id="basic-typeahead-multiple"
                                            labelKey="name"
                                            multiple
                                            selected={this.state.skill_fokus}
                                            options={this.state.list_skill}
                                            onChange={this.setHandleSkill}
                                            placeholder="can choose several type."
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Teaching Detail</label>
                                        <CKEditors
                                            activeclassName="p10"
                                            content={this.state.teaching_detail}
                                            events={{
                                                "change": this.changeTeachingDetail.bind(this),
                                            }}
                                            config={{
                                                toolbar: [
                                                    ["Bold", "Italic", 'Strike', 'RemoveFormat'],
                                                    ['Indent', 'Outdent'],
                                                    ['Link', 'Unlink'],
                                                    ['Styles', 'Format', 'Table', 'Blockquote', 'Maximize'],
                                                    ["Cut", "Copy", "Paste", "Pasteasplaintext", "Undo", "Redo"]
                                                ]
                                            }}
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <div className='form-group'>
                                            <label className='control-label'>Assignment</label>
                                            <textarea className='form-control' onChange={this.handleInput} name='assignment' value={this.state.assignment} />
                                        </div>
                                    </div>
                                    {
                                        this.state.dataType == 'add' ?
                                            <div className="form-group">
                                                <label htmlFor="none">Students <RequiredTag /></label>
                                                <div className="row">
                                                    {
                                                        this.state.list_students?.map((item, i) => {
                                                            return (
                                                                <div key={i} className="col-6">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" name='kode_register' onChange={() => this.toggleStudents(item.kode_register)} type="checkbox" value={item.kode_register} id={item.kode_register} />
                                                                        <label className="form-check-label" htmlFor={item.kode_register}>
                                                                            {item.nama_lengkap}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            : ''
                                    }
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveUpdate()}>{this.state.textSave}</Button>
                        <Button color="secondary" onClick={() => this.setState({ modalNote: false })}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    renderModalDelete() {
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                    <ModalHeader toggle={() => this.setState({ modalDelete: false })}>Delete Task</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete data?
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnSave} color="danger" onClick={(e) => this.saveDelete()}>Delete</Button>
                        <Button color="secondary" onClick={() => this.setState({ modalDelete: false })}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    render() {

        const columns = [
            {
                Header: 'Group ',
                accessor: 'code_register_grup',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["code_register_grup"] }),
                filterAll: true
            },
            {
                Header: 'Students',
                accessor: 'register',
                filterable: false,
                width: 300,
                Cell: (row) => (
                    <ul style={{ overflow: 'auto' }} className='row'>
                        {
                            row.original.register.length > 0 ?
                                row.original.register.map((item, index) => (
                                    <li style={{ fontSize: '10px' }} className='col' key={index}><i className='fa fa-caret-right txt-secondary m-r-10'></i> {item.nama_lengkap}</li>
                                ))
                                : <></>
                        }
                    </ul>
                ),
            },
            {
                Header: 'Start Date',
                accessor: 'start_date',
                style: { textAlign: 'center' },
                filterable: false,
                Cell: (row) => (
                    <>
                        {
                            row.original.start_date != null ?
                                Moment(row.original.start_date).format('DD MMM YY') : ''
                        }
                    </>
                )
            },
            {
                Header: 'End Date',
                accessor: 'end_date',
                filterable: false,
                style: { textAlign: 'center' },
                Cell: (row) => (
                    <>
                        {
                            row.original.end_date != null ?
                                Moment(row.original.end_date).format('DD MMM YY') : ''
                        }
                    </>
                )
            },
            {
                Header: 'Status',
                accessor: 'status',
                style: { textAlign: 'center' },
                width: 130,
                Cell: (row) => (
                    <>
                        {
                            row.original.status == 2 ?
                                <span className='badge badge-primary'>Finished</span>
                                : row.original.status == 1 ? <span onClick={e => this.showDateFinishDialog(row.original)} className='badge badge-success'>On Going</span> :
                                    <span onClick={e => this.showDateStartDialog(row.original)} className='badge badge-light'>Not Started</span>
                        }
                    </>
                ),
                Filter: () =>
                    <select value={this.state.filterStatus || ''} onChange={(e) => this.SetFilterStatus(e)}>
                        <option value=''>Show All</option>
                        <option value='2'>Finished</option>
                        <option value='1'>On Going</option>
                        <option value='0'>Not Started</option>
                    </select>,
            }
        ];

        const child_columns = [
            {
                Header: 'Date & Time',
                accessor: 'date_time',
                style: { textAlign: 'center' },
                Cell: (row) => (
                    <>{Moment(row.original.date_time).format('DD MMM Y hh:mm')}</>
                )
            },
            {
                Header: 'Skill Fokus',
                accessor: 'skill_focus',
                Cell: (row) => (
                    <div className='row ml-2'>
                        {
                            row.original.skill_focus.length > 0 ?
                                row.original.skill_focus.map((item, index) => (
                                    <span key={index} className='badge badge-dark mb-1'>{item.name}</span>
                                ))
                                : ''
                        }
                    </div>
                )
            },
            {
                Header: 'Teaching Details',
                accessor: 'teaching_detail',
                Cell: (row) => (
                    <div style={{ overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: row.original.teaching_detail }} />
                )
            },
            {
                Header: 'Assignment',
                accessor: 'assignment',
                style: { textAlign: 'center' },
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                width: 100,
                Cell: (row) => (
                    <>
                        <button className='btn btn-outline-primary btn-xs' type='button' style={{ border: 'none' }} onClick={() => this.handleEdit(row.original)}>
                            <i className="fa fa-pencil fa-2x"></i>
                        </button>
                        <button className='btn btn-outline-danger btn-xs' type='button' style={{ border: 'none' }} onClick={() => this.handleDelete(row.original)}>
                            <i className="fa fa-trash fa-2x"></i>
                        </button>
                    </>
                ),
                style: { textAlign: 'center' },
                sortable: false
            },
        ]

        return (
            <>
                <div className="tab-content active default" id="tab-1">
                    <div className="card">
                        <div className="card-body">
                            <div className="row m-1">
                                <div className="col-sm-12">
                                    <ReactTable
                                        data={this.state.groups}
                                        filtered={this.state.filtered}
                                        columns={columns}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                        filterable
                                        collapseOnDataChange={false}
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                        SubComponent={row => {
                                            const detail = row.original.register
                                            const notes = row.original.notes
                                            return (
                                                <div className='container p-4'>
                                                    <div className='row justify-content-between'>
                                                        <div className='col-sm-3'>
                                                            <h5><strong>Teaching Notes</strong><br /><span style={{ fontSize: '10px', paddingBottom: '0px' }} >({row.original.program.nama_program} - {row.original.type.description})</span></h5>
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            <button onClick={(e) => this.showModalNoted(detail)} style={{ float: 'right' }} className='btn btn-info btn-sm'>Add</button>
                                                        </div>
                                                    </div>
                                                    <ReactTable
                                                        data={notes}
                                                        columns={child_columns}
                                                        defaultPageSize={10}
                                                        className="-striped -highlight"
                                                        showPaginationBottom={true}
                                                    />
                                                </div>
                                            )
                                        }}
                                    />
                                    <Loader showLoading={this.state.loading} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDateDialog()}
                {this.renderNotedDialog()}
                {this.renderModalDelete()}
            </>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})


export default connect(reduxState, null)(withRouter(EnglishGroupClass));
