/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect } from "react";
import API_URL from "../../../ApiUrl";
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux'
import setRangeDate from '../../../helper/general-function';

const ReportProgramType = ({date}) => {

    const[dataProgram,setDataProgram] = useState([{source:[], consultants:[]}]);
    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }))

    const loadReportProgram = () => {
        let xdata = setRangeDate(date);
        const url = API_URL.superStudent + '/english-course/report-program-type';
        let formData = new FormData();
        formData.append('start_date', xdata.start)
        formData.append('end_date', xdata.end)
        axios.post(url, formData, {
            headers: { 'Authorization': `Bearer ${dataToken}` }
        }).then(response => response.data.data).then((data) => {
            setDataProgram(data)
        });
    }

    useEffect(() => {
        loadReportProgram()
    },[date])

    return(
        <>
            <Bar data={{
                labels: dataProgram.program,
                datasets: dataProgram.source,
                        }} options={{
                maintainAspectRatio: true,
                legend: {
                    display: true,
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                    }],
                yAxes: [{
                    stacked: true,
                }]
                },
         }}  />
        </>
    );

}

export default ReportProgramType;